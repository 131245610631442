import React, { useContext } from 'react';
import styled, { css } from 'styled-components';
import { DarkModeContext } from '../DarkModeContext';
import { primaryDarkColor } from '../../constant';
import { useMediaQuery } from '@mui/material';

const Newsletter = () => {

    const { darkMode } = useContext(DarkModeContext);
    const isDesktop = useMediaQuery('(min-width: 768px)');

    const containerStyles = {
        textAlign: 'center',
        backgroundColor: '#1A8F85',
        padding: '90px 50px',
        width: !isDesktop ? '' : '60%',
        margin: '0 auto',
        position: 'relative',
        '@media (max-width: 768px)': {
            padding: '40px',
        },
        '@media (max-width: 480px)': {
            padding: '30px',
        },
    };

    const headingStyles = {
        fontSize: '2.5rem',
        color: '#ffffff',
        fontWeight: 600,
        margin: 0,
        '@media (max-width: 768px)': {
            fontSize: '2rem',
        },
        '@media (max-width: 480px)': {
            fontSize: '1.5rem',
        },
    };

    const subHeadingStyles = {
        fontSize: '1rem',
        color: '#ffffff',
        fontWeight: 300,
        margin: '10px 0 30px 0',
        '@media (max-width: 768px)': {
            fontSize: '0.9rem',
        },
        '@media (max-width: 480px)': {
            fontSize: '0.8rem',
        },
    };

    const formStyles = {
        display: 'flex',
        flexDirection: isDesktop ? 'row' : 'column',
        gap: isDesktop ? 0 : 15,
        justifyContent: 'center',
        alignItems: 'center',
        '@media (max-width: 480px)': {
            flexDirection: 'column',
        },
    };

    const inputWrapperStyles = {
        display: 'flex',
        alignItems: 'center',
        backgroundColor: '#ffffff',
        borderRadius: 0,
        padding: isDesktop ? '10px 60px' : '10px 30px',
        boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
        marginRight: '10px',
        '@media (max-width: 480px)': {
            marginRight: 0,
            marginBottom: '10px',
        },
    };

    const inputStyles = {
        border: 'none',
        outline: 'none',
        fontSize: '1rem',
        padding: '15px 10px',
        borderRadius: '50px',
        flex: 1,
        '@media (max-width: 480px)': {
            fontSize: '0.9rem',
        },
    };

    const buttonStyles = {
        backgroundColor: '#333333',
        color: '#ffffff',
        fontSize: '1rem',
        padding: '25px 20px',
        border: 'none',
        borderRadius: 0,
        cursor: 'pointer',
        '@media (max-width: 480px)': {
            fontSize: '0.9rem',
        },
    };

    const applyStyles = (styles) => css(styles);

    const Container = styled.div`${applyStyles(containerStyles)}`;
    const Heading = styled.h1`${applyStyles(headingStyles)}`;
    const SubHeading = styled.p`${applyStyles(subHeadingStyles)}`;
    const Form = styled.form`${applyStyles(formStyles)}`;
    const InputWrapper = styled.div`${applyStyles(inputWrapperStyles)}`;
    const Input = styled.input`${applyStyles(inputStyles)}`;
    const Button = styled.button`${applyStyles(buttonStyles)}`;

    return (
        <div style={{ background: darkMode ? primaryDarkColor : '', padding: isDesktop ? '5% 0 7%' : '5% 2%' }}>
            <Container>
                <Heading>Subscribe Us</Heading>
                <SubHeading>
                    Subscribe Now and enjoy the freshness and purity of natural milk delivered right to your doorstep.
                </SubHeading>
                <Form style={{ bottom: '-10%', position: !isDesktop ? '' : 'absolute', left: !isDesktop ? 0 : '25%' }}>
                    <InputWrapper>
                        <img src="/images/email-icon.png" alt="Email Icon" style={{ width: '20px', height: '20px', marginRight: '10px' }} />
                        <Input type="email" placeholder="johndoe@gmail.com" />
                    </InputWrapper>
                    <Button>SUBSCRIBE</Button>
                </Form>
                <img src="/images/squares.svg" alt="Square Img" style={{ width: '100px', height: '100px', position: 'absolute', right: isDesktop ? '-5%' : '5%' }} />
            </Container>
        </div>
    );
};

export default Newsletter;