import { useMediaQuery } from '@mui/material';
import React, { useContext } from 'react';
import styled, { css } from 'styled-components';
import { primaryColor, primaryDarkColor } from '../../constant';
import { useTheme } from '@mui/material/styles';
import { DarkModeContext } from '../DarkModeContext';

const AboutSection = () => {

    const theme = useTheme();
    const matches_md = useMediaQuery(theme.breakpoints.down('md'));
    const { darkMode } = useContext(DarkModeContext);


    const containerStyles = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        color: darkMode ? 'white' : '',
        justifyContent: 'center',
        backgroundColor: darkMode ? primaryDarkColor : '',
        padding: '40px 20px',
        '@media (max-width: 768px)': {
            padding: '20px',
        },
        '@media (max-width: 480px)': {
            padding: '10px',
        },
    };

    const sectionStyles = {
        display: 'flex',
        alignItems: 'start',
        justifyContent: 'space-between',
        margin: '20px 0',
        width: '100%',
        maxWidth: '1200px',
        '@media (max-width: 768px)': {
            flexDirection: 'column',
            alignItems: 'center',
        },
    };

    const textContainerStyles = {
        flex: 1,
        padding: '0 20px',
        '@media (max-width: 768px)': {
            padding: '10px 0',
            textAlign: 'center',
        },
    };

    const imageContainerStyles = {
        flex: 1,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '20px',
        flexWrap: 'wrap',
        position: 'relative',
        '> div': {
            position: 'relative',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        img: {
            // width: '100%',
            maxWidth: '400px',
            height: 'auto',
            objectFit: 'cover',
            borderRadius: '10px',
            transition: '0.3s',
            '@media (max-width: 768px)': {
                maxWidth: '300px',
            },
        },
        'div:hover p': {
            display: 'block',
        },
        'p': {
            display: 'none',
            position: 'absolute',
            bottom: '0',
            left: '0',
            width: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.7)',
            color: 'white',
            padding: '10px',
            boxSizing: 'border-box',
            borderRadius: '10px',
            zIndex: 2,
            textAlign: 'center',
            fontFamily: 'Alegreya Sans, sans-serif',
        },
    };
    const applyStyles = (styles) => css(styles);

    const Container = styled.div`${applyStyles(containerStyles)}`;
    const Section = styled.div`${applyStyles(sectionStyles)}`;
    const TextContainer = styled.div`${applyStyles(textContainerStyles)}`;
    const ImageContainer = styled.div`${applyStyles(imageContainerStyles)}`;

    const aboutImageStyles = {
        position: 'absolute',
        left: matches_md ? 0 : '5%',
        top: 0,
        zIndex: 1,
        width: matches_md ? 300 : '400px',
    };

    const headingStyles = {
        position: 'relative',
        marginTop: matches_md ? '15%' : '8%',
        textAlign: 'center',
        fontSize: matches_md ? 13 : 17,
        fontWeight: 400,
        lineHeight: matches_md ? '5vw' : '',
        padding: '20px',
        zIndex: 2,
        fontFamily: 'Alegreya Sans, sans-serif',
    };

    return (
        <Container>

            <div style={{ position: 'relative', marginTop: '5%' }}>
                {/* {
                    darkMode && (
                        <div className='blurDiv2' style={{ zIndex: 2 }}></div>
                    )
                } */}
                <img src='/images/about-img.svg' style={aboutImageStyles} />
                <h3 style={headingStyles}>MilkPromise is dedicated to delivering the highest quality, fresh, and natural milk directly
                    from local farms to your doorstep. Our mission is to support small-scale farmers and provide our
                    customers with unprocessed, nutrient-rich milk that retains its natural goodness. We believe in
                    transparency, sustainability, and excellence, ensuring that every drop of milk you receive is pure and
                    untouched by harmful chemicals or preservatives.
                </h3>
                <ImageContainer style={{ gap: '20px', flexWrap: 'wrap' }}>
                    <img src="/images/about-milk-1.svg" alt="Image 1" />

                    <img src="/images/about-milk-2.svg" alt="Image 2" />
                    <img src="/images/milk-img-1.svg" alt="Image 1" />

                </ImageContainer>
                <center>

                    <h2 className='featuresHeading' style={{ zIndex: 99, marginTop: '5%', textAlign: matches_md ? 'left' : 'center', padding: matches_md ? '0 4%' : '', fontSize: 40, color: darkMode ? 'white' : '' }}>See what people say about <br /> MilkPromise</h2>
                </center>
            </div>

            <Section style={{ marginTop: '8%', position: 'relative' }}>

                <div className='blurDiv2' style={{ position: 'absolute', left: 0, top: 0, zIndex: -1 }}></div>
                <ImageContainer style={{ zIndex: 99 }}>
                    <img src="/images/about-milk-1.svg" alt="Image 1" />
                </ImageContainer>
                <TextContainer>
                    <h3 style={{ fontSize: matches_md ? 15 : 20, color: 'white', textAlign: 'left', marginTop: matches_md ? '' : '20%', }}>Our Mission</h3>
                    <p style={{ fontSize: matches_md ? 14 : 18, lineHeight: matches_md ? '4vw' : '1.5vw', textAlign: matches_md ? 'left' : '', marginTop: matches_md ? '' : '3%', fontFamily: 'Alegreya Sans, sans-serif', }}>

                        Our mission is to revolutionize the dairy industry by offering pure, unprocessed milk that
                        supports local farmers and promotes health and well-being among our customers. We are
                        committed to providing a seamless and eco-friendly milk delivery service, ensuring that our
                        customers enjoy the freshest milk possible while reducing our environmental impact.
                    </p>
                </TextContainer>
            </Section>
            <Section style={{ marginTop: '5%', position: 'relative' }}>
                <div className='blurDiv2' style={{ position: 'absolute', right: 0, top: 0, zIndex: -1 }}></div>
                <TextContainer style={{ order: matches_md ? 2 : 1 }}>
                    <h3 style={{ fontSize: matches_md ? 15 : 20, color: 'white', textAlign: 'left', marginTop: matches_md ? '' : '20%', }}>Our Vision</h3>
                    <p style={{ fontSize: matches_md ? 14 : 18, lineHeight: matches_md ? '4vw' : '1.5vw', textAlign: matches_md ? 'left' : '', marginTop: matches_md ? '' : '3%', fontFamily: 'Alegreya Sans, sans-serif', }}>Our vision is to become the leading provider of natural, farm-fresh milk in the region, known
                        for our commitment to quality, sustainability, and community support. We aim to expand our range
                        of dairy products while maintaining our core values of purity and excellence, fostering a healthier
                        and more sustainable future for all.</p>
                </TextContainer>
                <ImageContainer style={{ order: matches_md ? 1 : 2 }}>
                    <img src="/images/about-milk-2.svg" alt="Image 2" />
                </ImageContainer>
            </Section>
            <Section style={{ position: 'relative', marginTop: '5%' }}>
                <div className='blurDiv2' style={{ position: 'absolute', left: 0, top: 0, zIndex: -1 }}></div>
                <ImageContainer style={{ zIndex: 99 }}>
                    <img src="/images/milk-img-1.svg" alt="Image 1" />
                </ImageContainer>
                <TextContainer style={{ zIndex: 99 }}>
                    <p style={{ fontFamily: 'Alegreya Sans, sans-serif', fontSize: matches_md ? 14 : 18, lineHeight: matches_md ? '4vw' : '1.5vw', textAlign: matches_md ? 'left' : '', marginTop: matches_md ? '' : '20%' }}>
                        MilkPromise is dedicated to delivering the highest quality, fresh, and natural milk directly
                        from local farms to your doorstep. Our mission is to support small-scale farmers and provide our
                        customers with unprocessed, nutrient-rich milk that retains its natural goodness. We believe in
                        transparency, sustainability, and excellence, ensuring that every drop of milk you receive is pure and
                        untouched by harmful chemicals or preservatives.
                    </p>
                </TextContainer>
                {/* {
                    darkMode && (
                        <div className='blurDiv2' style={{ zIndex: 2, right: 0 }}></div>
                    )
                } */}
            </Section>
            <Section style={{ position: 'relative', marginTop: '5%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', }}>
                <h1 style={{ color: 'white', fontSize: matches_md ? 22 : 30, fontWeight: 600 }}>Our Upcoming Products</h1>
                <p style={{ textAlign: 'center', fontSize: matches_md ? 12 : 18, fontFamily: 'Alegreya Sans, sans-serif', }}>At MilkPromise, we are continually striving to expand our product line to meet the diverse needs of our customers.</p>
            </Section>

            <ImageContainer style={{ marginTop: '3%', gap: '20px', flexWrap: 'wrap', position: 'relative', zIndex: 99 }}>
                {/* <div className='blurDiv2' style={{ position: 'absolute', right: 0, top: 0, zIndex: -1 }}></div> */}
                <div style={{ position: 'relative' }}>
                    <img src="/images/curd.jpg" alt="Curd Image" style={{ height: '200px' }} />
                    <p>Pure and rich in flavor, our ghee will be made from the finest milk, ensuring unparalleled taste and quality</p>
                </div>
                <div style={{ position: 'relative' }}>
                    <img src="/images/Idly.jpg" alt="Idly Image" style={{ height: '200px', width: '300px' }} />
                    <p>Pure and rich in flavor, our ghee will be made from the finest milk, ensuring unparalleled taste and quality</p>
                </div>
                <div style={{ position: 'relative' }}>
                    <img src="/images/Dosa.jpg" alt="Dosa Image" style={{ height: '200px' }} />
                    <p>Pure and rich in flavor, our ghee will be made from the finest milk, ensuring unparalleled taste and quality</p>
                </div >
                <div style={{ position: 'relative' }}>
                    <img src="/images/ghee.jpg" alt="Ghee Image" style={{ height: '200px' }} />
                    <p>Our natural curd will be made from fresh milk, offering a creamy texture and probiotic benefits.</p>
                </div >
            </ImageContainer >

        </Container >
    );
};

export default AboutSection;
