import React, { useContext, useEffect, useState } from 'react';
import { useMediaQuery, Box, Typography } from '@mui/material';
import Header from '../Components/Header';
import Footer from '../Components/Footer';
import Newsletter from '../Components/Newsletter';
import { primaryColor, primaryDarkColor } from '../../constant';
import { DarkModeContext } from '../DarkModeContext';

const RefundsPolicy = () => {

    const isDesktop = useMediaQuery('(min-width: 1024px)');
    const isTablet = useMediaQuery('(min-width: 768px) and (max-width: 1023px)');
    const { darkMode } = useContext(DarkModeContext);

    const styles = {
        container: {
            display: 'flex',
            flexDirection: isDesktop ? 'row' : 'column',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: isDesktop ? '3%' : '20px 5px',
            backgroundColor: '#efffef',
        },
        leftSide: {
            flex: 1,
            padding: '20px',
            order: isDesktop ? 1 : 2
        },
        rightSide: {
            flex: 1,
            padding: '20px',
            display: 'flex',
            justifyContent: 'center',
            order: isDesktop ? 2 : 1
        },
        heading: {
            fontSize: isDesktop ? 45 : 32,
            fontWeight: 500,
            marginBottom: '20px',
            marginTop: 0,
        },
        highlight: {
            color: primaryColor,
        },
        description: {
            width: '90%',
            fontSize: '16px',
            marginBottom: '20px',
        },
        cardsContainer: {
            display: 'grid',
            padding: isDesktop ? '1% 4%' : '20px 5px',
            gridTemplateColumns: isDesktop ? 'repeat(3, 1fr)' : isTablet ? 'repeat(2, 1fr)' : '1fr',
            gap: '20px',
        },
        cardSection: {
            padding: isDesktop ? '3% 7%' : '40px 20px',
            position: 'relative'
        },
        image: {
            maxWidth: isDesktop ? '80%' : '100%',
            height: 'auto',
            borderRadius: '10px',
        },

        starIcon: {
            color: '#6F42C1',
            marginRight: '5px',
        },
        ratingText: {
            fontWeight: 'bold',
        },
        bookingText: {
            color: '#757575',
            marginLeft: '5px',
        },
        dottedLine: {
            borderBottom: '1px dotted #757575',
            flex: 1,
            marginLeft: '10px',
        }
    };


    return (
        <>
            <Header />

            <div style={{ ...styles.container, background: darkMode ? primaryDarkColor : '' }} className={darkMode ? '' : 'hero-section'} >
                {
                    darkMode && (
                        <div className='blurDiv2' style={{ zIndex: 2 }}></div>
                    )
                }
                <div style={styles.leftSide}>
                    <h2 style={{ ...styles.heading, color: darkMode ? 'white' : 'black', zIndex: 99 }}>Refund Policy</h2>
                </div>
                <div style={styles.rightSide}>
                </div>
            </div>

            <div style={{ padding: isDesktop ? '3% 18%' : '5%', fontSize: 17, background: darkMode ? primaryDarkColor : 'white', color: darkMode ? 'white' : 'black' }}>
                <div style={{ color: darkMode ? 'white' : 'black', lineHeight: 1.5 }}>
                    <h3 style={{ fontWeight: 600 }}>Refund Eligibility:</h3>
                    <ul style={{ listStyle: 'disc', paddingLeft: 20 }}>
                        <li>
                            Refunds are considered only if there is an issue with the quality or delivery of the milk.
                            Claims must be made within 24 hours of delivery.
                        </li>
                        <li>
                            If you receive a damaged or incorrect product, please contact us immediately with relevant
                            details (including photos, if possible).
                        </li>
                    </ul>

                    <h3 style={{ fontWeight: 600 }}>Refund Process:</h3>
                    <ul style={{ listStyle: 'disc', paddingLeft: 20 }}>
                        <li>
                            To request a refund, please contact our customer support team via email at
                            <a href="mailto:contact.milkpromise@gmail.com"> contact.milkpromise@gmail.com</a> or through our contact form on the website.
                        </li>
                        <li>
                            Provide your order number, details of the issue, and any supporting evidence (e.g., photos of
                            the damaged product).
                        </li>
                    </ul>

                    <h3 style={{ fontWeight: 600 }}>Refund Approval:</h3>
                    <ul style={{ listStyle: 'disc', paddingLeft: 20 }}>
                        <li>
                            Once your claim is received, we will review the details and determine the eligibility for a
                            refund.
                        </li>
                        <li>
                            If approved, a refund will be processed to the original payment method within 5-7 business
                            days.
                        </li>
                    </ul>

                    <h3 style={{ fontWeight: 600 }}>Non-Refundable Items:</h3>
                    <ul style={{ listStyle: 'disc', paddingLeft: 20 }}>
                        <li>
                            Refunds will not be provided for issues related to subscription changes, delivery timing
                            adjustments, or personal preference reasons.
                        </li>
                    </ul>

                    <h3 style={{ fontWeight: 600 }}>Exchange Policy:</h3>
                    <ul style={{ listStyle: 'disc', paddingLeft: 20 }}>
                        <li>
                            We do not offer exchanges. If you receive an incorrect item, a refund will be processed, and
                            you may place a new order for the correct product.
                        </li>
                    </ul>

                    <h3 style={{ fontWeight: 600 }}>Contact Information:</h3>
                    <ul style={{ listStyle: 'disc', paddingLeft: 20 }}>
                        <li>
                            For any refund-related queries, please reach out to us at
                            <a href="mailto:contact.milkpromise@gmail.com"> contact.milkpromise@gmail.com</a>.
                        </li>
                    </ul>
                </div>
            </div>

            <Newsletter />
            <Footer />
        </>
    );
};

export default RefundsPolicy;