import React, { useContext, useRef } from 'react';
import { Box, Typography, Grid, Paper, Avatar } from '@mui/material';
import { styled } from '@mui/system';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { primaryColor, primaryDarkColor } from '../../constant';
import { DarkModeContext } from '../DarkModeContext';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

export default function RecipesSection() {

    const sliderRef = useRef(null);
    const theme = useTheme();
    const matches_sm = useMediaQuery(theme.breakpoints.down('sm'));
    const matches_md = useMediaQuery(theme.breakpoints.down('md'));
    const { darkMode } = useContext(DarkModeContext);

    const settings = {
        dots: false,
        infinite: true,
        speed: 300,
        focusOnSelect: false,
        autoplay: true,
        slidesToShow: matches_md ? 1 : 3.5,
        slidesToScroll: matches_md ? 1 : 1,
        arrows: false,
    };

    const recipeCard = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '1rem',
        textAlign: 'center',
        border: '1px solid ' + primaryColor,
        marginBottom: matches_md ? '8%' : ''
    }

    const RecipeImage = styled(Avatar)({
        width: '200px',
        height: '200px',
        marginBottom: matches_md ? '5%' : '15%',
        marginTop: matches_md ? '-15%' : '-25%',
        zIndex: 99,
    });

    const recipes = [
        { name: 'Mysore Pak', image: '/images/mysorepak.png' },
        { name: 'Badam Milk', image: '/images/badammilk.jpg' },
        { name: 'Nannari Milk', image: '/images/pedha.jpeg' },
        { name: 'Ras Malai', image: '/images/rasmalai.jpg' },
        { name: 'Kheer', image: '/images/milkcake.png' },
        { name: 'Payasam', image: '/images/payasa.jpg' },
    ];


    const recipesSlider = () => {
        return recipes?.map((recipe, i) => (
            <div>
                <div
                    key={i}
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        padding: '7%',
                        borderRadius: 17,
                        position: 'relative',
                        margin: '20% 3% 5%',
                        color: darkMode ? 'white' : '',
                        boxShadow: 'none',
                        background: 'transparent',
                        textAlign: 'left'
                    }}
                >
                    <div style={recipeCard}>
                        <RecipeImage alt={recipe.name} src={recipe.image} />
                        <Typography variant="h6" style={{ color: primaryColor }}>{recipe.name}</Typography>
                    </div>

                </div>
            </div>
        ));
    };


    return (
        <>
            <Box sx={{ textAlign: 'center', paddingTop: '6%', padding: '3% 5% 6%', position: 'relative', background: 'white', paddingTop: '5%', backgroundColor: '#fff' }}>
                {/* {
                    darkMode && (
                        <div className='blurDiv2' style={{ zIndex: 2 }}></div>
                    )
                } */}
                <img src='/images/recipes-img.svg' style={{ position: 'absolute', left: '2%', top: '2%', width: matches_md ? 300 : '' }} />
                <center>
                    <h2 className='featuresHeading' style={{ textAlign: 'center', padding: matches_md ? '0 4%' : '', fontSize: 35, color: primaryColor, marginTop: matches_md ? '17%' : '8%' }}>
                        Milk Recipes
                    </h2>
                </center>

                <Slider ref={sliderRef} {...settings}>
                    {recipesSlider()}
                </Slider>

            </Box>
        </>
    )
}