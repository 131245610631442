import { useMediaQuery } from '@mui/material';
import React, { useContext } from 'react';
import { DarkModeContext } from '../DarkModeContext';
import { primaryColor, primaryDarkColor } from '../../constant';

const ContactForm = () => {

    const isDesktop = useMediaQuery('(min-width: 768px)');
    const { darkMode } = useContext(DarkModeContext);

    const styles = {
        container: {
            display: 'flex',
            flexDirection: isDesktop ? 'row' : 'column-reverse',
            padding: isDesktop ? '5% 0' : '20px',
            color: 'white',
            padding: isDesktop ? '3% 13%' : '10% 5%',
            backgroundColor: primaryDarkColor,
            position: 'relative',
            margin: '0 auto',
            color: 'black'
        },
        leftPanel: {
            flex: 1,
            padding: '20px',
            color: 'white',
            textAlign: isDesktop ? 'left' : 'center',
        },
        rightPanel: {
            flex: 1,
            backgroundColor: 'black',
            padding: isDesktop ? '40px' : '30px',
            border: darkMode ? `1px solid ${primaryColor}` : '',
            position: 'relative',
            color: 'white',
            borderRadius: '10px',
            marginBottom: isDesktop ? '0' : '20px', // Margin for bottom spacing in mobile view
        },
        title: {
            fontSize: '24px',
            fontWeight: '600',
            marginBottom: '20px',
        },
        text: {
            fontSize: '16px',
        },
        input: {
            width: '100%',
            padding: '10px',
            margin: '10px 0',
            borderRadius: '5px',
            border: '1px solid #ccc',
            fontSize: '16px',
        },
        button: {
            padding: '20px',
            backgroundColor: '#fff',
            color: '#000',
            border: 'none',
            borderRadius: '0',
            cursor: 'pointer',
            fontSize: '16px',
        },
        socialIcons: {
            display: 'flex',
            gap: '10px',
            marginTop: '10%',
            justifyContent: isDesktop ? 'left' : 'center'
        },
    };

    return (
        <div style={styles.container}>
            {/* <div className='blurDiv2' style={{ position: 'absolute', left: 0, top: 0 }}></div> */}
            {/* {
                darkMode && (
                    <div className='blurDiv2' style={{ zIndex: 2 }}></div>
                )
            } */}
            <div style={{ ...styles.leftPanel, zIndex: 99 }}>
                <h2 style={styles.title}>Reach us!</h2>

                <div style={{ marginTop: '10%' }}>
                    <h3 style={styles.text}>Visit us</h3>
                    <p style={styles.text}>#119, Billapura, Attibele-Sarjapura Road, Near Water Tank</p>
                    <p style={styles.text}>Bangalore, Karnataka, India - 562125</p>
                </div>
                <div style={{ marginTop: '10%' }}>
                    <h3 style={styles.text}>Call us</h3>
                    <p style={styles.text}>Mon-Fri from 8am to 5pm.</p>
                    <p style={styles.text}>+91 9844884464</p>
                </div>
                <div style={styles.socialIcons}>
                    <img src='/images/facebook.svg' style={{ width: 20, height: 20 }} />
                    <img src='/images/twitter.svg' style={{ width: 20, height: 20 }} />
                    <img src='/images/instagram.svg' style={{ width: 20, height: 20 }} />
                    <img src='/images/linkedin.svg' style={{ width: 20, height: 20 }} />
                </div>
            </div>
            <div style={styles.rightPanel}>
                <h2 style={styles.title}>Got ideas? We've got the skills. Let's team up.</h2>
                <p style={styles.text}>Tell us more about yourself and what you’ve got in mind.</p>
                <input type="text" placeholder="Your name" style={styles.input} />
                <input type="email" placeholder="you@company.com" style={styles.input} />
                <textarea placeholder="Tell us a little about the project..." style={{ ...styles.input, height: '100px' }} />
                <button style={styles.button}>Let's get started!</button>
            </div>
        </div>
    );
};

export default ContactForm;