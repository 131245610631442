import { useMediaQuery } from '@mui/material';
import React, { useContext, useState } from 'react';
import styled, { css } from 'styled-components';
import { primaryDarkColor } from '../../constant';
import { useTheme } from '@mui/material/styles';
import { Box, Button, Modal } from '@mui/material';
import { DarkModeContext } from '../DarkModeContext';
import SubscriptionForm from './SubscriptionForm';

const CowMilkSection = () => {

    const theme = useTheme();
    const matches_md = useMediaQuery(theme.breakpoints.down('md'));
    const matches_sm = useMediaQuery(theme.breakpoints.down('sm'));
    const { darkMode } = useContext(DarkModeContext);

    const [open, setOpen] = useState(false);
    const [formType, setFormType] = useState('subscribe');
    const [milkType, setMilkType] = useState('');

    const handleOpen = (type, milk) => {
        setFormType(type);
        setMilkType(milk);
        setOpen(true);
    };

    const handleClose = () => setOpen(false);

    const containerStyles = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        color: darkMode ? 'white' : '',
        justifyContent: 'center',
        backgroundColor: darkMode ? primaryDarkColor : '',
        padding: '15px 20px',
        '@media (max-width: 768px)': {
            padding: '20px',
        },
        '@media (max-width: 480px)': {
            padding: '10px',
        },
    };

    const sectionStyles = {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        margin: '20px 0',
        width: '100%',
        maxWidth: '1200px',
        '@media (max-width: 768px)': {
            flexDirection: 'column',
            alignItems: 'center',
        },
    };

    const itemContainerStyles = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        '@media (max-width: 768px)': {
            marginBottom: '20px',
        },
    };

    const imageContainerStyles = {
        flex: 1,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '20px',
        flexWrap: 'wrap',
        position: 'relative',
        '> div': {
            position: 'relative',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        img: {
            width: '100%',
            maxWidth: '400px',
            height: 'auto',
            borderRadius: '10px',
            transition: '0.3s',
            '@media (max-width: 768px)': {
                maxWidth: '300px',
            },
        },
    };

    const applyStyles = (styles) => css(styles);

    const Container = styled.div`${applyStyles(containerStyles)}`;
    const Section = styled.div`${applyStyles(sectionStyles)}`;
    const ItemContainer = styled.div`${applyStyles(itemContainerStyles)}`;
    const ImageContainer = styled.div`${applyStyles(imageContainerStyles)}`;
    const ButtonsContainer = styled(Box)({
        display: 'flex',
        width: '100%',
        justifyContent: 'center',
        gap: '1rem',
        marginTop: '5%',
    });

    const buttonStyles = (isOutline, type) => ({
        background: type === 'soon' ? 'yellow' : isOutline ? 'white' : '#1A8F85',
        color: type === 'soon' ? 'black' : isOutline ? '#1A8F85' : 'white',
        border: isOutline ? '1px solid #1A8F85' : 'none',
        marginTop: '1%',
        borderRadius: 0,
        padding: matches_md ? '10px 20px' : '10px 40px',
        boxShadow: 'none',
        width: matches_md ? '75%' : 'auto',
    });

    return (
        <Container>
            <div style={{ position: 'relative' }}>
                {/* {darkMode && <div className='blurDiv2' style={{ zIndex: 2 }}></div>} */}
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}></div>
                <Section>
                    <ItemContainer>
                        <h2 style={{ color: 'white', margin: '10px', marginBottom: '25px', fontSize: matches_md ? 25 : 30 }}>Cow Milk</h2>
                        <ImageContainer>
                            <img src="/images/cow-milk.png" alt="Cow Milk" style={{ zIndex: 99 }} />
                            <ButtonsContainer style={{ zIndex: 99 }}>
                                <Button variant="contained" style={buttonStyles(false)} onClick={() => handleOpen('orderOnce', 'Cow Milk')}>
                                    Order Once
                                </Button>
                                <Button variant="outlined" style={buttonStyles(true)} onClick={() => handleOpen('subscribe', 'Cow Milk')}>
                                    Subscribe
                                </Button>
                            </ButtonsContainer>
                        </ImageContainer>
                    </ItemContainer>

                    <ItemContainer>
                        <h2 style={{ color: 'white', margin: '10px', marginBottom: '25px', fontSize: matches_md ? 25 : 28 }}>A2 Desi Milk</h2>
                        <ImageContainer>
                            <img src="/images/a2-cow-milk.png" alt="A2 Desi milk" />
                            {/* <ButtonsContainer style={{ zIndex: 99 }}>
                                <Button variant="contained" style={buttonStyles(false)} onClick={() => handleOpen('orderOnce', 'A2 Desi Milk')}>
                                    Order Once
                                </Button>
                                <Button variant="outlined" style={buttonStyles(true)} onClick={() => handleOpen('subscribe', 'A2 Desi Milk')}>
                                    Subscribe
                                </Button>
                            </ButtonsContainer> */}

                            <ButtonsContainer style={{ zIndex: 99 }}>
                                <Button variant="contained" style={buttonStyles(false, 'soon')}>
                                    Coming Soon
                                </Button>
                            </ButtonsContainer>

                        </ImageContainer>
                    </ItemContainer>

                </Section>
            </div>


            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="subscription-form-modal"
                aria-describedby="subscription-form-modal-description"
            >
                <Box sx={{
                    maxHeight: '85%', overflowY: 'auto',
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    border: 'none',
                    borderRadius: 2,
                    background: darkMode ? primaryDarkColor : 'white',
                    boxShadow: 24,
                    p: '1% 0',
                    width: matches_md ? '95%' : '45%',
                }}>
                    <SubscriptionForm formType={formType} handleClose={handleClose} milkType={milkType} />
                </Box>
            </Modal>
        </Container>
    );
};

export default CowMilkSection;