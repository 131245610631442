import { useContext, useState } from "react";
import BenefitsHeroSection from "../Components/BenefitsHeroSection";
import BenefitsSection from "../Components/BenefitsSection";
import Footer from "../Components/Footer";
import Header from "../Components/Header";
import KnowYourMilk from "../Components/KnowYourMilk";
import NutritionTable from "../Components/NutritionTable";
import RecipesSection from "../Components/RecipesSection";
import SubscriptionForm from "../Components/SubscriptionForm";
import CowMilkSection from "../Components/CowMilkSection"
import { DarkModeContext } from "../DarkModeContext";
import { primaryDarkColor } from "../../constant";
import { Helmet } from 'react-helmet';

export default function MilkPage() {

    const { darkMode } = useContext(DarkModeContext);


    return (
        <>
            <Helmet>
                <title>Premium Organic A2 Milk in Bangalore | Fresh Cow Milk – MilkPromise</title>
                <meta
                    name="description"
                    content="Discover the best organic A2 cow milk in Bangalore, delivered fresh from our local
dairy farms in Billapura, Sarjapura, Sompura Gate and Handenahalli."
                />
                <meta
                    name="keywords"
                    content="Organic A2 Milk Bangalore, Fresh Cow Milk Bangalore, Dairy Products Bangalore,
Organic A2 Milk Billapura, Fresh Cow Milk Sarjapura"
                />
            </Helmet>

            <Header />
            <BenefitsHeroSection />
            <CowMilkSection />
            <div style={{ padding: '5% 0 0', background: darkMode ? primaryDarkColor : '' }}><BenefitsSection /></div>
            <div style={{ padding: '3% 0 0', background: darkMode ? primaryDarkColor : '' }}><NutritionTable /></div>
            <div style={{ padding: '8% 0', background: darkMode ? primaryDarkColor : '' }}><RecipesSection /></div>
            <KnowYourMilk />
            <Footer />
        </>
    )
}