import { Divider, useMediaQuery } from '@mui/material';
import React, { useState, useContext } from 'react';
import styled, { css } from 'styled-components';
import { primaryColor, primaryDarkColor } from '../../constant';
import { useTheme } from '@mui/material/styles';
import { DarkModeContext } from '../DarkModeContext';
import Paper from '@mui/material/Paper';

const FAQSection = () => {

    const theme = useTheme();
    const matches_md = useMediaQuery(theme.breakpoints.down('md'));
    const { darkMode } = useContext(DarkModeContext);
    const [openFaq, setOpenFaq] = useState(null);

    const toggleFaq = (index) => {
        setOpenFaq(openFaq === index ? null : index);
    };

    const containerStyles = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        color: darkMode ? 'white' : '',
        justifyContent: 'center',
        backgroundColor: darkMode ? primaryDarkColor : '',
        padding: '1px 20px',
        '@media (max-width: 768px)': {
            padding: '20px',
        },
        '@media (max-width: 480px)': {
            padding: '10px',
        },
    };

    const sectionStyles = {
        display: 'flex',
        alignItems: 'start',
        justifyContent: 'space-between',
        margin: '20px 0',
        width: '100%',
        maxWidth: '1200px',
        '@media (max-width: 768px)': {
            flexDirection: 'column',
            alignItems: 'center',
        },
    };

    const textContainerStyles = {
        flex: 1,
        padding: '0 20px',
        '@media (max-width: 768px)': {
            padding: '10px 0',
        },
    };

    const applyStyles = (styles) => css(styles);

    const Container = styled.div`${applyStyles(containerStyles)}`;
    const Section = styled.div`${applyStyles(sectionStyles)}`;
    const TextContainer = styled.div`${applyStyles(textContainerStyles)}`;

    const paperStyle = {
        padding: matches_md ? '10px 10px' : '10px 10px',
        width: matches_md ? '90%' : '700px',
        margin: '10px auto',
        backgroundColor: darkMode ? 'rgba(0, 0, 0, 0.8)' : 'transparent',
        color: darkMode ? 'white' : 'inherit',
        borderRadius: '10px',
        boxShadow: 'none',
        overflow: 'hidden',
    };

    return (
        <div style={{ background: darkMode ? primaryDarkColor : '', padding: matches_md ? '0 0 7%' : '0 2%', position: 'relative' }}>

            {/* {
                darkMode && (
                    <div className='blurDiv2' style={{ zIndex: 1 }}></div>
                )
            } */}

            <Container>
                {/* <div className='blurDiv2' style={{ position: 'absolute', right: 0, top: 0, zIndex: -1 }}></div> */}
                <div style={{ position: 'relative' }}>
                    <h1 style={{ fontWeight: 600, fontSize: matches_md ? 25 : 30, textAlign: 'center', marginTop: matches_md ? '' : '50px', marginBottom: matches_md ? '10px' : '30px' }}>
                        Frequently Asked
                        <br />
                        Questions
                    </h1>
                    <Section>
                        <Paper style={paperStyle}>

                            <TextContainer>
                                <h4 style={{ display: 'flex', justifyContent: 'space-between', cursor: 'pointer', fontWeight: 500, fontSize: matches_md ? 12 : 18 }} onClick={() => toggleFaq(0)}>
                                    What is MilkPromise?
                                    <span style={{ fontSize: matches_md ? '4vw' : '', paddingRight: 5 }}>{openFaq === 0 ? '-' : '+'}</span>
                                </h4>
                                {openFaq === 0 && <p style={{ fontWeight: 300, color: darkMode ? 'white' : '#7f8c8d', fontSize: matches_md ? 10 : 15 }}>
                                    MilkPromise is dedicated to delivering fresh, unprocessed milk directly from local farms to your doorstep. Our milk is sourced from small-scale farmers and is tested for quality and purity before being delivered to ensure you receive the best product possible.
                                </p>}
                                <Divider />
                                <h4 style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', cursor: 'pointer', fontWeight: 500, fontSize: matches_md ? 12 : 18 }} onClick={() => toggleFaq(1)}>
                                    What makes MilkPromise milk different from regular milk?
                                    <span style={{ fontSize: matches_md ? '4vw' : '', paddingRight: 5 }}>{openFaq === 1 ? '-' : '+'}</span>
                                </h4>
                                {openFaq === 1 && <p style={{ fontWeight: 300, color: darkMode ? 'white' : '#7f8c8d', fontSize: matches_md ? 10 : 15 }}>
                                    Our milk is unprocessed, meaning it does not go through pasteurization or homogenization. This helps retain its natural nutrients and taste. Additionally, we use food-grade aluminum foil pouches for eco-friendly packaging, reducing plastic waste.
                                </p>}
                                <Divider />
                                <h4 style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', cursor: 'pointer', fontWeight: 500, fontSize: matches_md ? 12 : 18 }} onClick={() => toggleFaq(2)}>
                                    How do you ensure the quality and safety of the milk?
                                    <span style={{ fontSize: matches_md ? '4vw' : '', paddingRight: 5 }}>{openFaq === 2 ? '-' : '+'}</span>
                                </h4>
                                {openFaq === 2 && <p style={{ fontWeight: 300, color: darkMode ? 'white' : '#7f8c8d', fontSize: matches_md ? 10 : 15 }}>
                                    Each batch of milk is rigorously tested for quality and purity, including micro-testing and fat content analysis. We ensure the milk is free from harmful chemicals and preservatives. Detailed test results are available upon request, providing complete transparency.
                                </p>}
                                <Divider />
                                <h4 style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', cursor: 'pointer', fontWeight: 500, fontSize: matches_md ? 12 : 18 }} onClick={() => toggleFaq(3)}>
                                    How does the subscription service work?
                                    <span style={{ fontSize: matches_md ? '4vw' : '', paddingRight: 5 }}>{openFaq === 3 ? '-' : '+'}</span>
                                </h4>
                                {openFaq === 3 && <p style={{ fontWeight: 300, color: darkMode ? 'white' : '#7f8c8d', fontSize: matches_md ? 10 : 15 }}>
                                    We offer flexible subscription plans for weekly, one, and two-month deliveries with discounts. You can choose the frequency and quantity of milk deliveries that best suit your needs, and you can easily manage your subscription through our website.
                                </p>}
                                <Divider />
                                <h4 style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', cursor: 'pointer', fontWeight: 500, fontSize: matches_md ? 12 : 18 }} onClick={() => toggleFaq(4)}>
                                    What are the delivery timings?
                                    <span style={{ fontSize: matches_md ? '4vw' : '', paddingRight: 5 }}>{openFaq === 4 ? '-' : '+'}</span>
                                </h4>
                                {openFaq === 4 && <p style={{ fontWeight: 300, color: darkMode ? 'white' : '#7f8c8d', fontSize: matches_md ? 10 : 15 }}>
                                    We offer two delivery slots to suit your convenience: morning before 7 AM and evening before 5 PM. This ensures that you receive fresh milk at a time that works best for you.
                                </p>}
                                <Divider />
                                <h4 style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', cursor: 'pointer', fontWeight: 500, fontSize: matches_md ? 12 : 18 }} onClick={() => toggleFaq(5)}>
                                    What should I do if I need to pause my subscription?
                                    <span style={{ fontSize: matches_md ? '4vw' : '', paddingRight: 5 }}>{openFaq === 5 ? '-' : '+'}</span>
                                </h4>
                                {openFaq === 5 && <p style={{ fontWeight: 300, color: darkMode ? 'white' : '#7f8c8d', fontSize: matches_md ? 10 : 15 }}>
                                    Our "Holiday Mode" feature allows you to pause your deliveries temporarily. Simply log in to your account on our website and activate Holiday Mode to suspend deliveries for the duration of your absence.
                                </p>}
                                <Divider />
                                <h4 style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', cursor: 'pointer', fontWeight: 500, fontSize: matches_md ? 12 : 18 }} onClick={() => toggleFaq(6)}>
                                    How do I contact customer support?
                                    <span style={{ fontSize: matches_md ? '4vw' : '', paddingRight: 5 }}>{openFaq === 6 ? '-' : '+'}</span>
                                </h4>
                                {openFaq === 6 && <p style={{ fontWeight: 300, color: darkMode ? 'white' : '#7f8c8d', fontSize: matches_md ? 10 : 15 }}>
                                    You can reach our customer service team via phone at +91- 9844884464 (Monday to Saturday, 8 AM to 8 PM) or email at contact.milkpromise@gmail.com. You can also fill out the contact form on our Contact Us page for assistance.
                                </p>}
                                <Divider />
                                <h4 style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', cursor: 'pointer', fontWeight: 500, fontSize: matches_md ? 12 : 18 }} onClick={() => toggleFaq(7)}>
                                    Are there any upcoming products?
                                    <span style={{ fontSize: matches_md ? '4vw' : '', paddingRight: 5 }}>{openFaq === 7 ? '-' : '+'}</span>
                                </h4>
                                {openFaq === 7 && <p style={{ fontWeight: 300, color: darkMode ? 'white' : '#7f8c8d', fontSize: matches_md ? 10 : 15 }}>
                                    Yes, we are excited to expand our product line with upcoming items such as ghee and curd. Both will be made from the finest quality milk to ensure superior taste and nutritional value.
                                </p>}
                                <Divider />
                                <h4 style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', cursor: 'pointer', fontWeight: 500, fontSize: matches_md ? 12 : 18 }} onClick={() => toggleFaq(8)}>
                                    How does MilkPromise support the environment?
                                    <span style={{ fontSize: matches_md ? '4vw' : '', paddingRight: 5 }}>{openFaq === 8 ? '-' : '+'}</span>
                                </h4>
                                {openFaq === 8 && <p style={{ fontWeight: 300, color: darkMode ? 'white' : '#7f8c8d', fontSize: matches_md ? 10 : 15 }}>
                                    We prioritize sustainability by using food-grade aluminum foil pouches instead of plastic packaging, reducing our environmental impact. By choosing MilkPromise, you are contributing to a cleaner, greener future.
                                </p>}
                                <Divider />
                                <h4 style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', cursor: 'pointer', fontWeight: 500, fontSize: matches_md ? 12 : 18 }} onClick={() => toggleFaq(9)}>
                                    Why should I choose MilkPromise?
                                    <span style={{ fontSize: matches_md ? '4vw' : '', paddingRight: 5 }}>{openFaq === 9 ? '-' : '+'}</span>
                                </h4>
                                {openFaq === 9 && <p style={{ fontWeight: 300, color: darkMode ? 'white' : '#7f8c8d', fontSize: matches_md ? 10 : 15 }}>
                                    Choosing MilkPromise means opting for fresh, natural milk that supports local farmers and sustainable practices. Our commitment to quality, transparency, and customer satisfaction ensures you receive the best dairy products delivered straight to your door.
                                </p>}
                            </TextContainer>
                        </Paper>
                    </Section>
                </div>
            </Container>
        </div>
    );
}

export default FAQSection;
