import React, { useContext, useRef } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Grid } from "@mui/material";
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { primaryColor, primaryDarkColor } from '../../constant';
import { DarkModeContext } from '../DarkModeContext';

export default function Testimonials() {

    const sliderRef = useRef(null);
    const theme = useTheme();
    const matches_sm = useMediaQuery(theme.breakpoints.down('sm'));
    const matches_md = useMediaQuery(theme.breakpoints.down('md'));
    const { darkMode } = useContext(DarkModeContext);

    const settings = {
        dots: false,
        infinite: true,
        speed: 300,
        focusOnSelect: false,
        autoplay: true,
        slidesToShow: matches_md ? 1 : 3.5,
        slidesToScroll: matches_md ? 1 : 1,
        arrows: false,
    };

    const features = [
        {
            name: 'Chetan',
            title: 'Top-Quality Fresh Milk and Eco Packaging',
            image: 'model-1.jpg',
            para: "MilkPromise and offers top-notch quality and fresh milk. Their service is commendable, and I applaud their commitment to environmentally friendly packaging."
        },
        {
            name: 'Srinivas',
            title: 'Punctual Deliveries and Reliable Service',
            image: 'model-2.jpg',
            para: `
            Although I've only been using Milk Promise for a week, I am already impressed with their
service. The milk is always fresh, and the deliveries have been punctual and hassle-free. It’s
reassuring to know that I can rely on them to meet my daily needs with such consistency in
such a short time.
Their team’s professionalism and attention to detail make me confident that I made the right
choice. I’m looking forward to continuing with Milk Promise and would highly recommend
them to anyone looking for reliable and quality milk delivery.
            `
        },
        {
            name: 'Satish',
            title: 'Glass Bottles: A Healthier, Greener Choice',
            image: 'model-1.jpg',
            para: `I've been using Milk Promise cow milk products for a while, and I'm glad they're using glass
bottles for packaging, which is a great initiative for the environment and health compared to
other milk companies that use plastic packaging. I'm looking forward to trying their buffalo
milk & A2 milk product.`
        },
        {
            name: 'Nithya',
            title: "Hygienic Milk Delivery in Glass Bottles",
            image: 'model-2.jpg',
            para: `
            Previously I used to take milk from local farmers who used to deliver the milk through big
cans and pour it into our cans. Then I started taking milk from MilkPromise, and I liked the
way they deliver the milk in glass bottles, sealed for hygiene. It looks very hygienic.`
        },
        {
            name: 'Lakshmi',
            title: 'Sustainable Fresh Milk, Supporting Local Farmers',
            image: 'model-1.jpg',
            para: `
            MilkPromise stands out with its high-quality, fresh milk directly from local farmers. Their service is impressive, and I appreciate their commitment to sustainable packaging. This makes them an excellent choice for anyone looking for healthy, environmentally conscious dairy products. Furthermore, their support for local farming contributes positively to the community, enhancing the overall impact of each purchase.
            `
        }
    ]

    const servicesSlider = () => {
        return features.map((item, i) => (
            <div>
                <div
                    key={i}
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        padding: '7%',
                        height: matches_md ? 320 : 300,
                        borderRadius: 17,
                        position: 'relative',
                        margin: '10% 3%',
                        color: 'black',
                        border: '1px solid #1A8F85',
                        boxShadow: 'none',
                        background:  'white',
                        textAlign: 'left'
                    }}
                >
                    <h3 style={{ margin: 0, fontWeight: 500, textAlign: 'left', fontSize: 20 }}>{item.title}</h3>

                    <p style={{ opacity: '60%', marginTop: '6%', fontSize: 13 }}>
                        {item.para}
                    </p>
                    <br />

                    <div style={{ display: 'flex', gap: '3%' }}>
                        <p style={{}}>-{item.name}</p>
                        {/* <img src='/images/stars.svg' style={{ width: 140 }} /> */}
                    </div>

                </div>
            </div>
        ));
    };




    return (
        <div style={{ padding: matches_md ? '8% 0 3%' : '10% 0 0', position: 'relative', background: '#fff'}}>
            <img src='/images/testimonials-img.svg' style={{ position: 'absolute', left: '2%', top: '2%', width: matches_md ? 300 : '' }} />
            {/* {
                darkMode && (
                    <div className='blurDiv2' style={{ zIndex: 2, right: 0 }}></div>
                )
            } */}
            <Grid container spacing={1} className='testimonialsSection'>
                {/* <div className='blurDiv2'></div> */}
                <Grid item xs={12} style={{ zIndex: 2, marginTop: matches_md ? '5%' : '' }}>
                    <center>
                        <h2 className='featuresHeading' style={{ textAlign: matches_md ? 'left' : 'center', padding: matches_md ? '0 4%' : '', fontSize: 35, color: primaryColor }}>See what people say about <br />MilkPromise</h2>
                    </center>

                    <div style={{ position: 'relative', borderRadius: 20, padding: matches_md ? '1%' : '2% 4%' }}>
                        <div style={{ background: '', width: '70%', position: 'absolute', left: '15%', height: '90%', borderRadius: 10 }}>
                        </div>
                        <Slider ref={sliderRef} {...settings}>
                            {servicesSlider()}
                        </Slider>
                    </div>

                </Grid>
            </Grid >

        </div >
    )
}