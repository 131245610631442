// import React, { useContext, useRef, useEffect, useState } from 'react';
// import { useMediaQuery } from '@mui/material';
// import { useNavigate } from 'react-router-dom';
// import { primaryColor, primaryDarkColor } from '../../constant';
// import { DarkModeContext } from '../DarkModeContext';

// const FreshMilk = () => {

//     const isSmallScreen = useMediaQuery('(max-width: 599px)');
//     const { darkMode } = useContext(DarkModeContext);
//     const isMediumScreen = useMediaQuery('(min-width: 600px) and (max-width: 1023px)');

//     const styles = {
//         container: {
//             display: 'flex',
//             flexDirection: isSmallScreen ? 'column' : 'row',
//             alignItems: 'start',
//             justifyContent: 'space-between',
//             padding: isSmallScreen ? '50px 20px' : '6% 0',
//             background: darkMode ? primaryDarkColor : 'linear-gradient(to right, #f7ffed, #FFFFFF)',
//             color: '#000',
//             position: 'relative',
//             overflow: 'hidden',
//             zIndex: 0
//         },
//         imageContainer: {
//             flex: 1,
//             display: 'flex',
//             justifyContent: 'center',
//             alignItems: 'center',
//         },
//         frontImage: {
//             width: '110%',
//             height: 'auto',
//             zIndex: 1,
//         },
//         backImage: {
//             width: '45%',
//             height: 'auto',
//             position: 'absolute',
//             transform: 'rotate(-15deg)',
//             right: '5%',
//             zIndex: 1,
//         },
//         contentContainer: {
//             flex: 1.5,
//             paddingLeft: isSmallScreen ? '0' : '20px',
//             textAlign: isSmallScreen ? 'center' : 'left',
//         },
//         heading: {
//             fontSize: isMediumScreen || isSmallScreen ? '30px' : 35,
//             width: isMediumScreen ? '' : isSmallScreen ? '' : '70%',
//             fontWeight: 500,
//             color: 'white',
//             marginLeft: isSmallScreen ? 0 : '5%',
//             lineHeight: 1,
//             textAlign: isSmallScreen ? 'left' : ''
//         },
//         description: {
//             fontSize: '16px',
//             color: '#555',
//             width: isMediumScreen ? '' : isSmallScreen ? '' : '85%',
//             marginBottom: '20px',
//             lineHeight: '1.6',
//         },
//         button: {
//             backgroundColor: 'black',
//             color: '#fff',
//             padding: '15px 30px',
//             borderRadius: '5px',
//             fontSize: '16px',
//             textDecoration: 'none',
//             display: 'inline-block',
//             marginTop: '10px',
//             cursor: 'pointer',
//         },
//         wave: {
//             position: 'absolute',
//             bottom: 0,
//             width: '100%',
//             zIndex: 1
//         },
//         wave2: {
//             position: 'absolute',
//             bottom: '10%',
//             right: 0,
//             width: '30%',
//             zIndex: 1
//         }
//     };

//     return (
//         <div style={{ ...styles.container }}>
//             <img className='' src="/images/milk-wave.svg" alt="CTA Illustration" style={styles.wave} />
//             <img className='' src="/images/milk-splash.svg" alt="CTA Illustration" style={styles.wave2} />
//             <div style={styles.contentContainer}>
//                 <h2 style={styles.heading}>
//                     Experience the joy of fresh milk delivered at your convenience with our reliable and timely service.
//                 </h2>
//             </div>
//             <div style={styles.imageContainer}>
//                 <img src="/images/milk-bottle.svg" alt="Milk Bottle" style={styles.backImage} />
//                 <img src="/images/milk-bottle.svg" alt="Milk Bottle" style={styles.frontImage} />
//             </div>
//         </div>
//     );
// };

// export default FreshMilk;




import React, { useContext } from 'react';
import { useMediaQuery } from '@mui/material';
import { primaryColor, primaryDarkColor } from '../../constant';
import { DarkModeContext } from '../DarkModeContext';

const FreshMilk = () => {
    const isSmallScreen = useMediaQuery('(max-width: 599px)');
    const { darkMode } = useContext(DarkModeContext);
    const isMediumScreen = useMediaQuery('(min-width: 600px) and (max-width: 1023px)');

    const styles = {
        container: {
            display: 'flex',
            flexDirection: isSmallScreen ? 'column' : 'row',
            alignItems: 'start',
            justifyContent: 'space-between',
            padding: isSmallScreen ? '50px 20px' : '6% 0',
            background: darkMode ? primaryDarkColor : 'linear-gradient(to right, #f7ffed, #FFFFFF)',
            color: '#000',
            position: 'relative',
            overflow: 'hidden',
            zIndex: 0
        },
        imageContainer: {
            flex: 1,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        frontImage: {
            width: '110%',
            height: 'auto',
            zIndex: 1,
            animation: 'float 3s ease-in-out infinite',
        },
        backImage: {
            width: '45%',
            height: 'auto',
            position: 'absolute',
            transform: 'rotate(-15deg)',
            right: '5%',
            zIndex: 1,
            animation: 'floatReverse 4s ease-in-out infinite',
        },
        contentContainer: {
            flex: 1.5,
            paddingLeft: isSmallScreen ? '0' : '20px',
            textAlign: isSmallScreen ? 'center' : 'left',
        },
        heading: {
            fontSize: isMediumScreen || isSmallScreen ? '30px' : '35px',
            width: isMediumScreen ? '100%' : isSmallScreen ? '100%' : '70%',
            fontWeight: 500,
            color: 'white',
            marginLeft: isSmallScreen ? 0 : '5%',
            lineHeight: 1,
            textAlign: isSmallScreen ? 'left' : 'left',
            animation: 'fadeIn 2s ease-in',
        },
        description: {
            fontSize: '16px',
            color: '#555',
            width: isMediumScreen ? '100%' : isSmallScreen ? '100%' : '85%',
            marginBottom: '20px',
            lineHeight: '1.6',
        },
        button: {
            backgroundColor: 'black',
            color: '#fff',
            padding: '15px 30px',
            borderRadius: '5px',
            fontSize: '16px',
            textDecoration: 'none',
            display: 'inline-block',
            marginTop: '10px',
            cursor: 'pointer',
            animation: 'pulse 2s infinite',
        },
        wave: {
            position: 'absolute',
            bottom: 0,
            width: '100%',
            zIndex: 1,
            animation: 'waveMotion 6s linear infinite',
        },
        wave2: {
            position: 'absolute',
            bottom: '10%',
            right: 0,
            width: '30%',
            zIndex: 1,
            animation: 'waveMotionReverse 6s linear infinite',
        }
    };

    return (
        <div style={styles.container}>
            <img className='wave' src="/images/milk-wave.svg" alt="CTA Illustration" style={styles.wave} />
            <img className='wave2' src="/images/milk-splash.svg" alt="CTA Illustration" style={styles.wave2} />
            <div style={styles.contentContainer}>
                <h2 style={styles.heading}>
                    Experience the joy of fresh milk delivered at your convenience with our reliable and timely service.
                </h2>
            </div>
            <div style={styles.imageContainer}>
                <img src="/images/milk-bottle.svg" alt="Milk Bottle" style={styles.backImage} />
                <img src="/images/milk-bottle.svg" alt="Milk Bottle" style={styles.frontImage} />
            </div>
        </div>
    );
};

export default FreshMilk;