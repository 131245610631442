import { useMediaQuery } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { primaryColor, primaryDarkColor } from '../../constant';
import { DarkModeContext } from '../DarkModeContext';

const BenefitsOfMilk = () => {

    const [gridColumns, setGridColumns] = useState('repeat(3, 1fr)');
    const theme = useTheme();
    const matches_md = useMediaQuery(theme.breakpoints.down('md'));
    const { darkMode } = useContext(DarkModeContext);

    useEffect(() => {
        const updateGridColumns = () => {
            if (window.innerWidth <= 480) {
                setGridColumns('1fr');
            } else if (window.innerWidth <= 768) {
                setGridColumns('repeat(2, 1fr)');
            } else {
                setGridColumns('repeat(3, 1fr)');
            }
        };

        updateGridColumns();
        window.addEventListener('resize', updateGridColumns);

        return () => {
            window.removeEventListener('resize', updateGridColumns);
        };
    }, []);

    const styles = {
        container: {
            padding: '20px',
            textAlign: 'center',
            background: darkMode ? primaryDarkColor : '#f0f8ff',
        },
        title: {
            fontSize: '24px',
            marginBottom: '20px',
            color: '#1A8F85',
            fontFamily: 'Arial, sans-serif',
        },
        grid: {
            display: 'grid',
            gridTemplateColumns: gridColumns,
            gap: '20px',
            alignItems: 'center',
            justifyItems: 'center',
        },
        item: {
            maxWidth: '300px',
            position: 'absolute',
            textAlign: 'left',
        },
        itemTitle: {
            fontSize: '18px',
            marginBottom: '10px',
            color: '#1A8F85',
        },
        itemText: {
            fontSize: '14px',
            color:  'black',
        },
        image: {
            background:'#fff',
            width: '35%',
        },
    };

    return (
        <>
            <div style={{ padding: '3% 0 6%', position: 'relative', background: '#fff'}}>
                <img src='/images/benefits-img.svg' style={{ position: 'absolute', left: '4%', top: 10, width: matches_md ? 300 : '', zIndex: 99 }} />
                {/* {
                    darkMode && (
                        <div className='blurDiv2' style={{ zIndex: 2 }}></div>
                    )
                } */}
                <div style={{ position: 'relative', marginTop: '8%', zIndex: 99 }}>
                    <center>
                        <h2 className='featuresHeading' style={{ textAlign: 'center', padding: matches_md ? '0 4%' : '', fontSize: 40, marginTop: matches_md ? '10%' : '' }}><span style={{ color: primaryColor }}>
                            Benefits of Milk
                        </span></h2>
                    </center>
                </div>
            </div>

            {
                matches_md ?
                    <>
                        <div style={{background:'#fff'}}>
                            <img src="/images/benefits-milk-img.svg" alt="Kids drinking milk" style={{ ...styles.image, width: '100%', zIndex: 99 }} />
                            <div style={{ padding: '3% 5%', position: 'relative',}}>
                                <div style={{ ...styles.item, position: 'relative', maxWidth: '90%' }}>
                                    <h3 style={styles.itemTitle}>Bone Health</h3>
                                    <p style={styles.itemText}>
                                        Milk is rich in calcium and vitamin D, both essential for maintaining strong bones and teeth.
                                        Regular consumption helps build bone density and prevent conditions like osteoporosis.
                                    </p>
                                </div>
                                <div style={{ ...styles.item, position: 'relative', maxWidth: '90%', marginTop: '7%', zIndex: 99 }}>
                                    <h3 style={styles.itemTitle}>Hydration</h3>
                                    <p style={styles.itemText}>
                                        With its high water content, milk helps keep the body hydrated, making it an effective choice
                                        for replenishing fluids after physical exertion.
                                    </p>
                                </div>

                                <div style={{ ...styles.item, position: 'relative', maxWidth: '90%', marginTop: '7%', zIndex: 99 }}>
                                    <h3 style={styles.itemTitle}>Muscle Growth and Repair</h3>
                                    <p style={styles.itemText}>
                                        The high-quality protein in milk provides essential amino acids necessary for muscle growth,
                                        repair, and recovery, making it ideal for athletes and those engaged in physical activities.
                                    </p>
                                </div>
                                {/* {
                                    darkMode && (
                                        <div className='blurDiv2' style={{ zIndex: 2, right: 0 }}></div>
                                    )
                                } */}
                                <div style={{ ...styles.item, position: 'relative', maxWidth: '90%', marginTop: '7%', zIndex: 99 }}>
                                    <h3 style={styles.itemTitle}>Nutrient-Rich</h3>
                                    <p style={styles.itemText}>
                                        Milk is a powerhouse of essential nutrients, including calcium, vitamin D, vitamin B12,
                                        potassium, and protein, supporting overall health and well-being.
                                    </p>
                                </div>
                                <div style={{ ...styles.item, position: 'relative', maxWidth: '90%', marginTop: '7%', zIndex: 99 }}>
                                    <h3 style={styles.itemTitle}>Digestive Health</h3>
                                    <p style={styles.itemText}>
                                        Milk contains probiotics and lactose, which can aid in maintaining a healthy digestive system
                                        and promoting beneficial gut bacteria.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </>
                    :
                    <>
                        <div style={{ padding: '10% 6%', display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'relative', background:  'white' }}>
                            {/* <div className='blurDiv2' style={{ position: 'absolute', right: 0, top: 0 }}></div> */}
                            <img src="/images/hex-1.svg" style={{ height: 120, position: 'absolute', top: '20%', zIndex: -1 }} />
                            <img src="/images/hex-2.svg" style={{ height: 120, position: 'absolute', right: '33%', top: '29%' }} />
                            <img src="/images/hex-3.svg" style={{ height: 120, position: 'absolute', right: '37%', bottom: '13%' }} />
                            <img src="/images/hex-4.svg" style={{ height: 120, position: 'absolute', left: '37%', bottom: '13%' }} />
                            <img src="/images/hex-5.svg" style={{ height: 120, position: 'absolute', left: '33%', top: '29%' }} />

                            <img src="/images/benefits-milk-img.svg" alt="Kids drinking milk" style={styles.image} />
                            <div style={{ ...styles.item, top: 0 }}>
                                <h3 style={styles.itemTitle}>Bone Health</h3>
                                <p style={styles.itemText}>
                                    Milk is rich in calcium and vitamin D, both essential for maintaining strong bones and teeth.
                                    Regular consumption helps build bone density and prevent conditions like osteoporosis.
                                </p>
                            </div>
                            <div style={{ ...styles.item, right: '15%', top: '12%' }}>
                                <h3 style={styles.itemTitle}>Hydration</h3>
                                <p style={styles.itemText}>
                                    With its high water content, milk helps keep the body hydrated, making it an effective choice
                                    for replenishing fluids after physical exertion.
                                </p>
                            </div>
                            <div style={{ ...styles.item, left: '17%', top: '12%' }}>
                                <h3 style={styles.itemTitle}>Muscle Growth and Repair</h3>
                                <p style={styles.itemText}>
                                    The high-quality protein in milk provides essential amino acids necessary for muscle growth,
                                    repair, and recovery, making it ideal for athletes and those engaged in physical activities.
                                </p>
                            </div>
                            {/* {
                                darkMode && (
                                    <div className='blurDiv2' style={{ zIndex: 2, right: 0 }}></div>
                                )
                            } */}
                            <div style={{ ...styles.item, left: '15%', bottom: '5%' }}>
                                <h3 style={styles.itemTitle}>Nutrient-Rich</h3>
                                <p style={styles.itemText}>
                                    Milk is a powerhouse of essential nutrients, including calcium, vitamin D, vitamin B12,
                                    potassium, and protein, supporting overall health and well-being.
                                </p>
                            </div>
                            <div style={{ ...styles.item, right: '15%', bottom: '5%' }}>
                                <h3 style={styles.itemTitle}>Digestive Health</h3>
                                <p style={styles.itemText}>
                                    Milk contains probiotics and lactose, which can aid in maintaining a healthy digestive system
                                    and promoting beneficial gut bacteria.
                                </p>
                            </div>
                        </div>
                    </>
            }
        </>
    );
};

export default BenefitsOfMilk;