import React, { useContext } from 'react';
import { useMediaQuery } from '@mui/material';
import { primaryColor, primaryDarkColor } from '../../constant';
import { DarkModeContext } from '../DarkModeContext';
import { useInView } from 'react-intersection-observer';

const GetFreshMilk = () => {
    const isSmallScreen = useMediaQuery('(max-width: 599px)');
    const { darkMode } = useContext(DarkModeContext);
    const isMediumScreen = useMediaQuery('(min-width: 600px) and (max-width: 1023px)');
    const { ref, inView } = useInView({ triggerOnce: true, threshold: 0.2 });

    const styles = {
        container: {
            display: 'flex',
            flexDirection: isSmallScreen ? 'column' : 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: isMediumScreen || isSmallScreen ? '20px' : '',
            backgroundColor: primaryDarkColor,
            color: 'white',
            position: 'relative',
            overflow: 'hidden',
        },
        textContainer: {
            flex: 1,
            maxWidth: '50%',
            position: 'relative',
            zIndex: 99,
            textAlign: isSmallScreen ? 'center' : 'left',
            opacity: inView ? 1 : 0,
            transform: inView ? 'translateX(0)' : 'translateX(-100px)',
            transition: 'all 1s ease-in-out',
        },
        leftText: {
            fontSize: isSmallScreen ? '16px' : '24px',
            color: 'white',
            top: '10%',
            position: isSmallScreen ? 'relative' : 'absolute',
            lineHeight: '1.8',
            zIndex: 99,
            marginTop: isSmallScreen ? '6%' : '',
            marginLeft: isSmallScreen ? '6%' : '10%',
            textAlign: isSmallScreen ? 'left' : 'left',
        },
        imageContainer: {
            flex: 1,
            position: 'relative',
            display: 'flex',
            zIndex: 99,
            justifyContent: 'center',
            alignItems: 'center',
            opacity: inView ? 1 : 0,
            transform: inView ? 'translateX(0)' : 'translateX(100px)',
            transition: 'all 1s ease-in-out',
        },
        image: {
            width: 300,
        },
    };

    return (
        <div ref={ref} style={styles.container}>
            <div style={styles.textContainer}>
                <div style={styles.leftText}>
                    By choosing MilkPromise, you are not only getting the best milk but also contributing to the
                    livelihood of local farmers. Our seamless process ensures that you get the freshest milk while helping
                    the community thrive.
                </div>
            </div>
            <div style={styles.imageContainer}>
                <img src="/images/get-milk-img.png" alt="Get Milk" style={styles.image} />
            </div>
        </div>
    );
};

export default GetFreshMilk;
