import React, { useContext, useState } from 'react';
import styled, { css } from 'styled-components';
import { DarkModeContext } from '../DarkModeContext';
import { primaryDarkColor } from '../../constant';
import { useMediaQuery } from '@mui/material';

const reasons = [
  {
    icon: 'no-process.svg', label: 'No Processing',
    description: "At MilkPromise, we are dedicated to delivering the purest milk straight to your doorstep. Unlike much of the processed milk on the market, our milk is never processed. We adhere to a straightforward yet meticulous process of collection, chilling, and direct delivery. By avoiding pasteurization and powdering, we ensure that the milk retains its natural nutrients and wholesome goodness, exactly as nature intended. Enjoy the unadulterated taste and nutritional benefits of farm fresh milk, straight from our dairy to your home."
  },
  {
    icon: 'no-plastic.svg', label: 'No Plastic',
    description: 'Embrace Eco-Friendly Milk Delivery with MilkPromise Bid farewell to plastic waste with MilkPromise’s environmentally friendly milk delivery service. Unlike typical milk deliveries that add to plastic pollution, we use food-grade aluminum foil pouches for packaging. Our commitment to sustainability and environmental care helps us reduce our carbon footprint and support a greener future. Join us in our mission for a plastic-free world and enjoy the pure, natural taste of fresh milk, delivered directly from our farm to your home without any waste.'
  },
  {
    icon: 'natural-milk.svg', label: 'Natural Pure Milk',
    description: 'Supporting Ethical Dairy Practices. At MilkPromise, we are passionate about supporting small-scale farmers through ethical dairy practices. We source milk exclusively from farmers with fewer than f ive cattle, ensuring it comes from farms where cows are naturally nurtured. Unlike large dairies that confine cattle and use chemically enhanced feed, our focus is on farms that practice traditional, humane methods. Cows graze on organic feed and are hand-milked, preserving the integrity and authenticity of the milk. Enjoy the wholesome goodness of milk from happy, naturally raised cows with MilkPromise.'
  },
  {
    icon: 'touch-free.svg', label: 'Touch Free',
    description: "Experience unparalleled hygiene and convenience with MilkPromise's touch-free milk dispensing. From the moment our farmers collect the milk to its transport in specially designed chilled containers, every step is meticulously touch-free. Our advanced delivery system ensures your milk arrives at your doorstep without any human contact, hassle-free and completely touch-free. Embrace a new standard of cleanliness and efficiency with MilkPromise's innovative touch-free milk dispensing."
  },
  {
    icon: 'micro-testing.svg', label: 'Micro Testing',
    description: "At MilkPromise, your safety and satisfaction are our top priorities. We uphold our commitment to quality through an extensive micro-testing process, involving detailed tests before the milk enters our system. You can trust that the comprehensive results, including precise fat content and other essential components, are readily available to you. With a focus on transparency, we offer on demand chemical testing, empowering you to verify the quality and purity of your milk right at home. Enjoy complete peace of mind with MilkPromise's thorough and customer-focused micro testing procedures."
  },
  {
    icon: 'aio.svg', label: 'All In One',
    description: "Simplify your dairy experience with the all-in-one MilkPromise website. Seamlessly manage your milk delivery bookings and access comprehensive milk data, including nutritional information and balance details, all conveniently available at your fingertips. Enjoy the benefits of a valet service that effortlessly caters to all your dairy needs. With the holiday mode feature, you can pause your deliveries at your convenience, ensuring complete control and flexibility. Discover the ultimate in convenience and customization with MilkPromise, your trusted companion for all your dairy requirements."
  },
];

const WhyChooseUs = () => {

  const { darkMode } = useContext(DarkModeContext);
  const [selectedReason, setSelectedReason] = useState(reasons[0]);
  const isDesktop = useMediaQuery('(min-width: 768px)');

  const containerStyles = {
    textAlign: 'center',
    backgroundColor: darkMode ? primaryDarkColor : '#1A8F85',
    padding: '8% 10%',
    position: 'relative',
    '@media (max-width: 768px)': {
      padding: '20px',
    },
    '@media (max-width: 480px)': {
      padding: '20px',
    },
  };

  const headingStyles = {
    fontSize: '3rem',
    color: 'rgba(255, 255, 255, 0.3)',
    fontWeight: 300,
    margin: 0,
    '@media (max-width: 768px)': {
      fontSize: '2.5rem',
    },
    '@media (max-width: 480px)': {
      fontSize: '2rem',
    },
  };

  const subHeadingStyles = {
    fontSize: isDesktop ? 40 : 35,
    color: '#ffffff',
    fontWeight: 600,
    margin: '20px 0',
    '@media (max-width: 768px)': {
      fontSize: '1.25rem',
    },
    '@media (max-width: 480px)': {
      fontSize: '1rem',
    },
  };

  const iconsContainerStyles = {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    margin: '20px 0',
    '@media (max-width: 768px)': {
      justifyContent: 'space-around',
    },
    '@media (max-width: 480px)': {
      flexDirection: 'row',
      alignItems: 'center',
    },
  };


  const iconWrapperStyles = {
    margin: '10px',
    textAlign: 'center',
    cursor: 'pointer',
    img: {
      width: '100px',
      height: '100px',
      borderRadius: '7px',
      transition: 'box-shadow 0.3s ease-in-out',
      '@media (max-width: 768px)': {
        width: '60px',
        height: '60px',
      },
      '@media (max-width: 480px)': {
        width: '50px',
        height: '50px',
      },
      '&:hover': {
        boxShadow: '0 0 15px 5px rgba(255, 255, 255, 0.6)',
      },
    },
    p: {
      marginTop: '10px',
      color: '#ffffff',
      fontSize: '1rem',
      '@media (max-width: 768px)': {
        fontSize: '0.9rem',
      },
      '@media (max-width: 480px)': {
        fontSize: '0.8rem',
      },
    },
  };


  const bannerStyles = {
    backgroundColor: '#ffffff',
    padding: '20px',
    marginTop: '20px',
    position: 'relative',
    display: 'inline-block',
    '&::before': {
      content: "''",
      position: 'absolute',
      top: '0',
      left: '0', // Changed from '50%' to '0' to move the triangle to the top left
      transform: 'translateX(0) translateY(-100%)', // Added translateY to position correctly
      width: '0',
      height: '0',
      borderLeft: '15px solid transparent',
      borderRight: '15px solid transparent',
      borderBottom: '15px solid #ffffff',
    },
    p: {
      margin: '0',
      color: '#44c6b8',
      fontSize: '1rem',
    },
  };

  const applyStyles = (styles) => css(styles);

  const Container = styled.div`${applyStyles(containerStyles)}`;
  const Heading = styled.h1`${applyStyles(headingStyles)}`;
  const SubHeading = styled.h2`${applyStyles(subHeadingStyles)}`;
  const IconsContainer = styled.div`${applyStyles(iconsContainerStyles)}`;
  const IconWrapper = styled.div`${applyStyles(iconWrapperStyles)}`;
  const Banner = styled.div`${applyStyles(bannerStyles)}`;

  return (
    <Container>
      {/* {
        darkMode && (
          <div className='blurDiv2' style={{ zIndex: 2 }}></div>
        )
      } */}
      <img src='/images/reasons-img.svg' style={{ position: 'absolute', left: '2%', top: '2%', width: isDesktop ? '' : 300 }} />
      <SubHeading style={{ marginTop: isDesktop ? '6%' : '20%' }}>Reason to Choose MILKPROMISE</SubHeading>
      <IconsContainer style={{ marginTop: '3%', zIndex: 99 }}>
        {reasons?.map((reason, index) => (
          <IconWrapper key={index} onClick={() => setSelectedReason(reason)} style={{ zIndex: 99 }}>
            <img src={`/images/${reason.icon}`} alt={reason.label} style={{
              borderRadius: 7, height: 100, width: 100, zIndex: 99,
            }} />
            <p>{reason.label}</p>
          </IconWrapper>
        ))}
      </IconsContainer>
      <Banner style={{ width: isDesktop ? 800 : '90%', padding: '3%' }}>
        <p>{selectedReason.description}</p>
      </Banner>
    </Container>
  );
};

export default WhyChooseUs;