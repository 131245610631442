import { Divider, useMediaQuery } from '@mui/material';
import React, { useState, useContext } from 'react';
import styled, { css } from 'styled-components';
import { primaryColor, primaryDarkColor } from '../../constant';
import { useTheme } from '@mui/material/styles';
import { DarkModeContext } from '../DarkModeContext';
import Paper from '@mui/material/Paper';

const WhyChooseSection = () => {

    const theme = useTheme();
    const matches_md = useMediaQuery(theme.breakpoints.down('md'));
    const { darkMode } = useContext(DarkModeContext);

    const containerStyles = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        color: darkMode ? 'white' : '',
        justifyContent: 'center',
        backgroundColor: darkMode ? primaryDarkColor : '',
        padding: '1px 20px',
        '@media (max-width: 768px)': {
            padding: '20px',
        },
        '@media (max-width: 480px)': {
            padding: '10px',
        },
    };

    const sectionStyles = {
        display: 'flex',
        alignItems: 'start',
        justifyContent: 'space-between',
        margin: '20px 0',
        width: '100%',
        maxWidth: '1200px',
        '@media (max-width: 768px)': {
            flexDirection: 'column',
            alignItems: 'center',
        },
    };

    const textContainerStyles = {
        flex: 1,
        padding: '0 20px',
        '@media (max-width: 768px)': {
            padding: '10px 0',
        },
    };

    const applyStyles = (styles) => css(styles);

    const Container = styled.div`${applyStyles(containerStyles)}`;
    const Section = styled.div`${applyStyles(sectionStyles)}`;
    const TextContainer = styled.div`${applyStyles(textContainerStyles)}`;

    const paperStyle = {
        padding: matches_md ? '20px' : '5px 6px',
        width: matches_md ? '80%' : '70%',
        margin: '6px',
        backgroundColor: darkMode ? 'rgba(0, 0, 0, 0.8)' : 'white',
        color: darkMode ? 'white' : 'black',
        color: darkMode ? 'white' : 'inherit',
        borderRadius: '10px',
        boxShadow: 'none'
    };

    return (
        <div style={{ background: darkMode ? primaryDarkColor : '', position: 'relative', padding: '5% 0' }}>
            <Container>
                {/* <div className='blurDiv2' style={{ position: 'absolute', right: 0, top: 0, zIndex: -1 }}></div> */}
                <div style={{ position: 'relative' }}>
                    <Section style={{ position: 'relative', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <h1 style={{ color: 'white', fontSize: matches_md ? 25 : 30, fontWeight: 600, marginTop: '-2%' }}>Why MilkPromise?</h1>
                        <div style={{ display: 'flex', justifyContent: 'center', flexDirection: matches_md ? 'column' : 'row', marginTop: matches_md ? '10px' : '30px', marginBottom: matches_md ? '10px' : '30px' }}>
                            <Paper style={paperStyle}>
                                <TextContainer>
                                    <h4 style={{ fontSize: matches_md ? '3vw' : '1vw', fontFamily: 'Alegreya Sans, sans-serif', fontWeight: 500, marginBottom: '-2%' }}>Reason 01</h4>
                                    <h4 style={{ color: 'white', fontSize: matches_md ? '4.5vw' : '1.5vw', fontFamily: 'Alegreya Sans, sans-serif', fontWeight: '600', marginBottom: '-0.5%' }}>Quality and Freshness</h4>
                                    <p style={{ fontWeight: 300, fontSize: 15, fontFamily: 'Alegreya Sans, sans-serif', color: '#7f8c8d' }}>We ensure that our milk is collected, tested, and delivered to you
                                        within hours, retaining its natural nutrients and freshness</p>
                                </TextContainer>
                            </Paper>
                            <Paper style={paperStyle}>
                                <TextContainer>
                                    <h4 style={{ fontSize: matches_md ? '3vw' : '1vw', fontFamily: 'Alegreya Sans, sans-serif', fontWeight: 500, marginBottom: '-2%' }}>Reason 02</h4>
                                    <h4 style={{ color: 'white', fontSize: matches_md ? '4.5vw' : '1.5vw', fontFamily: 'Alegreya Sans, sans-serif', fontWeight: '600', marginBottom: '-0.5%' }}>Natural and Pure</h4>
                                    <p style={{ fontWeight: 300, fontSize: 15, fontFamily: 'Alegreya Sans, sans-serif', color: '#7f8c8d' }}>Our milk is free from harmful chemicals, preservatives, and processing,
                                        providing you with the purest form of milk.</p>
                                </TextContainer>
                            </Paper>
                            <Paper style={paperStyle}>
                                <TextContainer>
                                    <h4 style={{ fontSize: matches_md ? '3vw' : '1vw', fontFamily: 'Alegreya Sans, sans-serif', fontWeight: 500, marginBottom: '-2%' }}>Reason 03</h4>
                                    <h4 style={{ color: 'white', fontSize: matches_md ? '4.5vw' : '1.5vw', fontFamily: 'Alegreya Sans, sans-serif', fontWeight: 'bold', marginBottom: '-0.5%' }}>Supporting Farmers</h4>
                                    <p style={{ fontWeight: 300, fontSize: 15, fontFamily: 'Alegreya Sans, sans-serif', color: '#7f8c8d' }}>We work closely with small-scale farmers, ensuring fair prices and
                                        sustainable farming practices</p>
                                </TextContainer>
                            </Paper>
                        </div>

                    </Section>
                </div>
            </Container>
        </div>
    )

}

export default WhyChooseSection;