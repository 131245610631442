import React, { useContext, useState, useRef } from 'react';
import { Drawer, IconButton, useMediaQuery } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { primaryColor, primaryDarkColor } from '../../constant';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useNavigate } from 'react-router-dom';
import { DarkModeContext } from '../DarkModeContext';
import { IoMoon, IoSunny } from 'react-icons/io5';

const Header = () => {

    const { darkMode, setDarkMode } = useContext(DarkModeContext);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [servicesOpen, setServicesOpen] = useState(false);
    const isDesktop = useMediaQuery('(min-width: 768px)');
    const isTabletOrMobile = useMediaQuery('(max-width: 767px)');
    const navigate = useNavigate();
    const timeoutRef = useRef(null);

    const toggleDrawer = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setDrawerOpen(open);
    };

    const toggleDarkMode = () => {
        setDarkMode(!darkMode);
    };

    const handleMouseEnter = () => {
        clearTimeout(timeoutRef.current);
        setServicesOpen(true);
    };

    const handleMouseLeave = () => {
        timeoutRef.current = setTimeout(() => {
            setServicesOpen(false);
        }, 300); // Adjust the delay as needed
    };

    const styles = {
        container: {
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: darkMode ? primaryDarkColor : '',
            color: darkMode ? '#fff' : '#000',
            // position: 'sticky',
            top: 0,
            zIndex: 1000,
            paddingTop: '1%',
            alignItems: 'center',
            justifyContent: 'center'

        },
        topBar: {
            display: isDesktop ? 'flex' : 'none',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '0 20px',
            color: darkMode ? '#b0b0b0' : '#000',
            height: 30,
            fontSize: '14px',
            backgroundColor: darkMode ? '#202020' : 'white',
        },
        header: {
            width: isDesktop ? '55%' : '87%',
            display: 'flex',
            justifyContent: isDesktop ? 'center' : 'space-between',
            alignItems: 'center',
            backgroundColor: darkMode ? primaryDarkColor : '',
            padding: isDesktop ? '0 20px' : '5px 20px',
            border: `2px solid ${primaryColor}`,
            borderRadius: '50px',
            overflow: 'hidden',
            marginBottom: '1%'
        },
        logo: {
            cursor: 'pointer'
        },
        nav: {
            display: isDesktop ? 'flex' : 'none',
            alignItems: 'center',
            position: 'relative',
        },
        navItem: {
            margin: '0 30px',
            color: darkMode ? 'white' : 'black',
            fontSize: '16px',
            textDecoration: 'none',
            cursor: 'pointer',
            position: 'relative',
            padding: '10px 0',
        },
        dropdownMenu: {
            position: 'fixed',
            top: '70px',
            left: 0,
            right: 0,
            width: '70%',
            margin: 'auto',
            display: servicesOpen ? 'flex' : 'none',
            backgroundColor: darkMode ? primaryDarkColor : '#fff',
            zIndex: 10,
            padding: '2px 20px',
            borderRadius: 10,
            zIndex: 999,
            boxShadow: darkMode ? '0 0 20px #255249' : '0 0 20px gainsboro',
            // borderBottom: '1px solid ' + primaryColor,
            // borderTop: '1px solid ' + primaryColor,
            justifyContent: 'space-between',
        },
        dropdownColumn: {
            flex: 1,
            padding: '20px',
            '&:last-child': {
                borderRight: 'none',
            }
        },
        dropdownItem: {
            padding: '10px 20px',
            color: darkMode ? '#fff' : '#000',
            cursor: 'pointer',
            textAlign: 'left',
        },
        contactButton: {
            backgroundColor: darkMode ? 'white' : 'black',
            color: darkMode ? 'black' : 'white',
            padding: '10px 20px',
            borderRadius: '5px',
            cursor: 'pointer',
            textDecoration: 'none',
        },
        contactButtonMobile: {
            backgroundColor: 'black',
            color: '#fff',
            padding: '10px 20px',
            borderRadius: '5px',
            width: '50%',
            textAlign: 'center',
            marginTop: '10%',
            cursor: 'pointer',
            textDecoration: 'none',
        },
        drawerContent: {
            width: '250px',
            padding: '20px',
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: darkMode ? primaryDarkColor : '#fff',
            color: darkMode ? '#fff' : '#000',
            height: '100%',
        },
        drawerNavItem: {
            margin: '10px 0',
            color: darkMode ? '#fff' : '#000',
            fontSize: '16px',
            textDecoration: 'none',
            cursor: 'pointer'
        },
        topBarItem: {
            marginBottom: '10px',
            color: darkMode ? '#b0b0b0' : '#000',
        },
        menuButton: {
            display: isDesktop ? 'none' : 'block',
            color: darkMode ? 'white' : 'black',
            display: 'flex',
            alignItems: 'center'
        },
        darkModeToggle: {
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
        },
        darkModeIcon: {
            marginLeft: '10px',
        },
        button: {
            backgroundColor: darkMode ? 'white' : 'black',
            color: darkMode ? 'black' : '#fff',
            padding: '15px',
            borderRadius: '5px',
            marginLeft: '3%',
            fontSize: '16px',
            textDecoration: 'none',
            display: 'inline-block',
        },
        serviceContainer: {
            padding: isDesktop ? '10px' : '20px',
            textAlign: isDesktop ? 'left' : 'center',
            maxWidth: '400px',
            margin: '0 auto',
            display: 'flex',
            alignItems: 'center',
            gap: 10,
            flexDirection: 'column',
            justifyContent: 'center'
        },
        heading: {
            fontSize: '24px',
            fontWeight: '600',
            marginBottom: '10px',
            color: darkMode ? 'white' : primaryColor
        },
        description: {
            fontSize: '16px',
            color: '#555',
            marginBottom: '20px'
        },
        serviceButton: {
            backgroundColor: darkMode ? 'white' : 'black',
            color: darkMode ? 'black' : '#fff',
            padding: '15px',
            borderRadius: '5px',
            fontSize: '16px',
            border: 'none',
            textDecoration: 'none',
            cursor: 'pointer',
            display: 'inline-block',
        },
        icon: {
            marginLeft: '10px',
            fontSize: '16px'
        },
        topBarContainer: {
            display: 'flex',
            justifyContent: isTabletOrMobile ? 'center' : 'flex-end',
            alignItems: 'center',
            padding: '5px 20px',
            backgroundColor: darkMode ? '#202020' : 'white',
            fontSize: '14px',
            gap: '10px',
        },
        item: {
            display: 'flex',
            alignItems: 'center',
            color: darkMode ? 'white' : '#000',
        },
        topBarIcon: {
            marginRight: '5px',
        },
        separator: {
            height: '20px',
            width: '2px',
            backgroundColor: '#ccc',
            margin: '0 10px',
        },
        link: {
            textDecoration: 'none',
            display: 'flex',
            alignItems: 'center',
            color: darkMode ? 'white' : 'black'
        }
    };

    const handleClick = (pathDir) => {
        navigate(pathDir);
        window.scrollTo(0, 0);
    };

    const darkModeToggle = () => {
        const toggleStyles = {
            toggleContainer: {
                cursor: 'pointer',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                margin: '20px',
                marginLeft: isTabletOrMobile ? 0 : '20px'
            },
            toggle: {
                width: '60px',
                height: '35px',
                backgroundColor: darkMode ? '#555555' : '#d1d1d1',
                borderRadius: '30px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                position: 'relative',
                transition: 'background-color 0.3s ease',
            },
            toggleCircle: {
                width: '22px',
                height: '22px',
                backgroundColor: '#ffffff',
                borderRadius: '50%',
                position: 'absolute',
                right: darkMode ? '57%' : '',
                left: darkMode ? '' : '57%',
                transition: 'transform 0.3s ease',
                transform: darkMode ? 'translateX(30px)' : 'translateX(-30px)',
            },
            toggleLabel: {
                fontSize: 13,
                color: darkMode ? '#ffffff' : '#000000',
                fontWeight: '500',
                marginLeft: darkMode ? '' : '35%',
                marginRight: darkMode ? '35%' : '',
            }
        };

        return (
            <div style={toggleStyles.toggleContainer} onClick={toggleDarkMode}>
                <div style={toggleStyles.toggle}>
                    <div style={toggleStyles.toggleCircle}></div>
                    <p style={toggleStyles.toggleLabel}>{darkMode ? <IoMoon style={{ fontSize: 20 }} /> : <IoSunny style={{ fontSize: 22 }} />}</p>
                </div>
            </div>
        );
    };

    return (
        <div style={styles.container}>
            <div style={styles.header}>

                {
                    !isDesktop && (
                        <div style={styles.logo} onClick={() => handleClick('/')}>
                            <img src={darkMode ? '/images/white-logo.png' : '/images/main-logo.png'} style={{ width: 60 }} />
                        </div>
                    )
                }

                <div style={styles.nav}>
                    <p
                        className={!darkMode ? 'nav-item-light' : 'nav-item'}
                        style={styles.navItem}
                        onClick={() => handleClick('/')}
                    >
                        Home
                    </p>
                    <p
                        className={!darkMode ? 'nav-item-light' : 'nav-item'}
                        style={styles.navItem}
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                    >
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            Products <ArrowDropDownIcon />
                        </div>
                        <div
                            style={styles.dropdownMenu}
                            onMouseEnter={handleMouseEnter}
                            onMouseLeave={handleMouseLeave}
                        >
                            <div style={styles.dropdownColumn}>
                                <div style={styles.serviceContainer} onClick={() => handleClick('/products')}>
                                    <img src='/images/header-milk-img.jpg' style={{ borderRadius: '50%', width: 100, height: 100 }} />
                                    <h3 style={styles.heading}>Milk</h3>
                                </div>
                            </div>
                            <div style={{ ...styles.dropdownColumn }}>
                                <div style={styles.serviceContainer}>
                                    <img src='/images/curd.jpg' style={{ borderRadius: '50%', width: 100, height: 100 }} />
                                    <h3 style={styles.heading}>Curd</h3>
                                    <p style={{ margin: 0, opacity: '70%', fontSize: 12, padding: 0 }}>COMMING SOON</p>
                                </div>
                            </div>
                            <div style={{ ...styles.dropdownColumn, borderRight: 'none' }}>
                                <div style={styles.serviceContainer}>
                                    <img src='/images/ghee.jpg' style={{ borderRadius: '50%', width: 100, height: 100 }} />
                                    <h3 style={styles.heading}>Ghee</h3>
                                    <p style={{ margin: 0, opacity: '70%', fontSize: 12, padding: 0 }}>COMMING SOON</p>
                                </div>
                            </div>
                            <div style={{ ...styles.dropdownColumn, borderRight: 'none' }}>
                                <div style={styles.serviceContainer}>
                                    <img src='/images/Idly.jpg' style={{ borderRadius: '50%', width: 100, height: 100 }} />
                                    <h3 style={styles.heading}>Idli Batter</h3>
                                    <p style={{ margin: 0, opacity: '70%', fontSize: 12, padding: 0 }}>COMMING SOON</p>
                                </div>
                            </div>
                            <div style={{ ...styles.dropdownColumn, borderRight: 'none' }}>
                                <div style={styles.serviceContainer}>
                                    <img src='/images/Dosa.jpg' style={{ borderRadius: '50%', width: 100, height: 100 }} />
                                    <h3 style={styles.heading}>Dosa Batter</h3>
                                    <p style={{ margin: 0, opacity: '70%', fontSize: 12, padding: 0 }}>COMMING SOON</p>
                                </div>
                            </div>
                        </div>
                    </p>

                    <div style={styles.logo} onClick={() => handleClick('/')}>
                        <img src={darkMode ? '/images/white-logo.png' : '/images/main-logo.png'} style={{ width: 70 }} />
                    </div>

                    <p className={!darkMode ? 'nav-item-light' : 'nav-item'} style={styles.navItem} onClick={() => handleClick('/about')}>About MilkPromise</p>
                    <p className={!darkMode ? 'nav-item-light' : 'nav-item'} style={styles.navItem} onClick={() => handleClick('/contact')}>Contact Us</p>
                </div>
                {/* {isDesktop && darkModeToggle()} */}
                {!isDesktop && (
                    <IconButton style={styles.menuButton} onClick={toggleDrawer(true)}>
                        <MenuIcon style={{ height: 35, width: 35 }} />
                    </IconButton>
                )}
            </div>
            <Drawer anchor="right" open={drawerOpen} onClose={toggleDrawer(false)}>
                <div style={styles.drawerContent}>
                    {/* <div style={styles.darkModeToggle}>
                        {darkModeToggle()}
                    </div> */}
                    <p className="drawer-nav-item" style={styles.drawerNavItem} onClick={() => handleClick('/')}>Home</p>
                    <p className="drawer-nav-item" style={styles.drawerNavItem} onClick={() => handleClick('/products')}>Products</p>
                    <p className="drawer-nav-item" style={styles.drawerNavItem} onClick={() => handleClick('/about')}>About Us</p>
                    <p className="drawer-nav-item" style={styles.drawerNavItem} onClick={() => handleClick('/contact')}>Contact</p>
                </div>
            </Drawer>
        </div>
    );
};

export default Header;
