import { useMediaQuery } from '@mui/material';
import React, { useContext } from 'react';
import styled, { css } from 'styled-components';
import { primaryColor, primaryDarkColor } from '../../constant';
import { useTheme } from '@mui/material/styles';
import { DarkModeContext } from '../DarkModeContext';

const KnowYourMilk = () => {

    const theme = useTheme();
    const matches_md = useMediaQuery(theme.breakpoints.down('md'));
    const { darkMode } = useContext(DarkModeContext);


    const containerStyles = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '40px 20px',
        '@media (max-width: 768px)': {
            padding: '20px',
        },
        '@media (max-width: 480px)': {
            padding: '10px',
        },
    };

    const sectionStyles = {
        display: 'flex',
        alignItems: 'start',
        justifyContent: 'space-between',
        margin: '20px 0',
        width: '100%',
        maxWidth: '1200px',
        '@media (max-width: 768px)': {
            flexDirection: 'column',
            alignItems: 'center',
        },
    };

    const textContainerStyles = {
        flex: 1,
        padding: '0 20px',
        '@media (max-width: 768px)': {
            padding: '10px 0',
            textAlign: 'center',
        },
    };

    const imageContainerStyles = {
        flex: 1,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        img: {
            width: '100%',
            maxWidth: '400px',
            height: 'auto',
            '@media (max-width: 768px)': {
                maxWidth: '300px',
            },
        },
    };

    const applyStyles = (styles) => css(styles);

    const Container = styled.div`${applyStyles(containerStyles)}`;
    const Section = styled.div`${applyStyles(sectionStyles)}`;
    const TextContainer = styled.div`${applyStyles(textContainerStyles)}`;
    const ImageContainer = styled.div`${applyStyles(imageContainerStyles)}`;

    return (
        <Container style={{ position: 'relative', background: darkMode ? primaryDarkColor : '', color: darkMode ? 'white' : '' }}>
            {/* <div className='blurDiv2' style={{ position: 'absolute', right: 0, top: 0 }}></div> */}
            {/* <div className='blurDiv2' style={{ position: 'absolute', left: 0, bottom: 0 }}></div> */}
            <img src='/images/know-milk-img.svg' style={{ position: 'absolute', left: '4%', top: 0, width: matches_md ? 300 : '' }} />

            <div style={{ position: 'relative', marginTop: '2%' }}>
                <center>
                    <h2 className='featuresHeading' style={{ marginTop: '15%', textAlign: matches_md ? 'left' : 'center', padding: matches_md ? '0' : '', fontSize: 40, }}><span style={{ color: 'white' }}>Know Your Milk</span></h2>
                </center>
            </div>

            <Section style={{ marginTop: '8%', position: 'relative' }}>

                <ImageContainer style={{ zIndex: 99 }}>
                    <img src="/images/kym-1.png" alt="Image 1" />
                </ImageContainer>
                <TextContainer style={{ zIndex: 99, textAlign: matches_md ? 'left' : '' }}>
                    <p style={{ fontSize: 14 }}>
                        Holstein cow milk is a cornerstone of the dairy industry, celebrated for its high yield and rich nutritional profile. It provides essential nutrients like calcium, vitamin D, and high-quality protein, supporting bone health, muscle repair, and overall well-being. Versatile in culinary uses, it enhances various recipes and dairy products. While beneficial, some may experience lactose intolerance or sensitivity to A1 beta-casein. Holstein milk is widely accessible and supports local farmers, contributing to the agricultural economy. Choosing Holstein milk ensures a nutrient-rich diet, promotes sustainable farming, and offers numerous health benefits.
                    </p>
                </TextContainer>
            </Section>
            <Section style={{ marginTop: '5%' }}>

                <TextContainer style={{ zIndex: 99, order: matches_md ? 2 : 1, textAlign: matches_md ? 'left' : '' }}>
                    <p style={{ fontSize: 14 }}>
                        A2 cow milk from Gir and Sahiwal cows is a superior choice for those seeking a digestible and
                        nutrient-rich alternative to regular milk. Known for its exclusive A2 beta-casein protein, this
                        milk is easier to digest and may reduce digestive discomfort associated with A1 protein. Rich
                        in essential nutrients like calcium, vitamin D, and high-quality protein, A2 milk supports bone
                        health, muscle repair, and overall well-being. By choosing A2 milk from Gir and Sahiwal cows,
                        you support sustainable and ethical farming practices. Enjoy the natural, pure benefits of A2
                        cow milk for a healthier and more wholesome diet.
                    </p>
                </TextContainer>
                <ImageContainer style={{ order: matches_md ? 1 : 2, zIndex: 99 }}>
                    <img src="/images/kym-2.png" alt="Image 2" />
                </ImageContainer>
            </Section>

        </Container>
    );
};

export default KnowYourMilk;