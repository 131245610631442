import { useContext } from "react";
import ContactForm from "../Components/ContactForm";
import Footer from "../Components/Footer";
import Header from "../Components/Header";
import Newsletter from "../Components/Newsletter";
import { DarkModeContext } from "../DarkModeContext";
import { useMediaQuery } from "@mui/material";
import { primaryDarkColor } from "../../constant";
import { Helmet } from 'react-helmet';

export default function Contact() {

    const { darkMode } = useContext(DarkModeContext);
    const isDesktop = useMediaQuery('(min-width: 768px)');

    return (
        <>
<Helmet>
<title>Milk Delivery in Billapura, Sarjapura, Attibele & Sompura Gate | MilkPromise</title>
<meta
name="description"
content="MilkPromise delivers fresh farm milk to homes in Billapura, Sarjapura, Sompura
Gate and Handenahalli. Order today for daily delivery of premium A2 milk."
/>
<meta
name="keywords"
content="Milk Delivery Billapura, Organic A2 Milk Sarjapura, Cow Milk Delivery Sompura Gate,
Farm Fresh Milk Bangalore"
/>
</Helmet>
            <Header />
            <ContactForm />
            <Newsletter />
            <div style={{ background: darkMode ? primaryDarkColor : '', paddingTop: isDesktop ? '' : '10%' }}><Footer /></div>
        </>
    )
}