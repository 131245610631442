import { useMediaQuery } from '@mui/material';
import React, { useContext } from 'react';
import { Box, Typography, Button } from '@mui/material';
import { styled } from '@mui/system';
import { DarkModeContext } from '../DarkModeContext';
import { primaryDarkColor } from '../../constant';
import { useTheme } from '@mui/material/styles';

const BenefitsHeroSection = () => {

    const theme = useTheme();
    const isDesktop = useMediaQuery('(min-width: 768px)');
    const matches_md = useMediaQuery(theme.breakpoints.down('md'));
    const { darkMode } = useContext(DarkModeContext);

    const QuoteBox = styled(Box)({
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
        flexDirection: 'column',
        background: darkMode ? primaryDarkColor : '',
        padding: isDesktop ? '5% 20% 2%' : '2rem',
        textAlign: 'center',
    });

    const QuoteText = styled(Typography)({
        fontSize: isDesktop ? 30 : '1.4rem',
        fontWeight: '600',
        marginBottom: '0rem',
    });


    return (
        <QuoteBox>
            <img src='/images/quote-1.svg' style={{ position: 'absolute', left: '5%', top: '5%', width: '8%' }} />
            <img src='/images/quote-2.svg' style={{ position: 'absolute', right: '5%', bottom: '0%', width: '8%' }} />
            <QuoteText style={{ color: 'white', zIndex: 99, fontWeight: 600 }}>
                Lets enrich our lives by taking advantage of the numerous health benefits of milk.
            </QuoteText>
        </QuoteBox>
    );
};

export default BenefitsHeroSection;