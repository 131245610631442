import './App.css';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from './Website/Pages/Home';
import About from './Website/Pages/About';
import MilkPage from './Website/Pages/MilkPage';
import Contact from './Website/Pages/Contact';
import { DarkModeProvider } from './Website/DarkModeContext';
import RefundsPolicy from './Website/Pages/RefundsPolicy';
import Terms from './Website/Pages/Terms';

function App() {
  return (
    <DarkModeProvider>
      <AppContent />
    </DarkModeProvider>
  );
}

function AppContent() {
  return (
    <div className="App">

      <Router>
        <Routes>
          <Route element={<Home />} path="/" />
          <Route element={<About />} path="/about" />
          <Route element={<MilkPage />} path="/products" />
          <Route element={<Contact />} path="/contact" />

          <Route element={<RefundsPolicy />} path="/refund-policy" />
          <Route element={<Terms />} path="/terms" />

          {/* <Route path="*" element={<ErrorPage />} /> */}

        </Routes>
      </Router>

    </div>
  );
}

export default App;
