import React, { useContext } from 'react';
import { useMediaQuery } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import PhoneIcon from '@mui/icons-material/Phone';
import { MdOutlineMail } from "react-icons/md";
import { DarkModeContext } from '../DarkModeContext';
import { primaryColor, primaryDarkColor } from '../../constant';

const Footer = () => {

    const navigate = useNavigate()
    const isSmallScreen = useMediaQuery('(max-width: 599px)');
    const isDesktop = useMediaQuery('(min-width: 768px)');
    const isMediumScreen = useMediaQuery('(min-width: 600px) and (max-width: 1023px)');
    const { darkMode } = useContext(DarkModeContext);

    const handleClick = (pathDir) => {
        navigate(pathDir);
        window.scrollTo(0, 0);
    };

    const styles = {
        container: {
            color: '#000',
            display: 'flex',
            paddingTop: '10%',
            color: darkMode ? 'white' : '#000',
            flexDirection: isSmallScreen ? 'column' : 'row',
            justifyContent: 'space-between',
            background: darkMode ? primaryDarkColor : '#fff',
            alignItems: isSmallScreen || isMediumScreen ? 'center' : 'flex-start',
            padding: isMediumScreen || isSmallScreen ? '20px' : '4% 8%',

        },
        column: {
            flex: 1,
            padding: '0 20px',
            zIndex: 99,
            marginBottom: isSmallScreen ? '20px' : '0',
            textAlign: isSmallScreen ? 'center' : 'left',

        },
        logo: {

            cursor: 'pointer'
        },
        description: {
            fontSize: '14px',
            marginBottom: '20px',
        },
        link: {
            color: darkMode ? 'white' : '#333',
            textDecoration: 'none',
            display: 'block',
            fontSize: 14,
            marginBottom: '10px',
            cursor: 'pointer'
        },
        socialIcons: {
            display: 'flex',
            marginTop: '20px',
        },
        socialIcon: {
            marginRight: '20px',
        },
        bottomBar: {
            marginTop: '-15%',
            background: darkMode ? primaryDarkColor : '',
            display: 'flex',
            padding: isMediumScreen || isSmallScreen ? '1px' : '1px 1px 10px',
            marginBottom: isSmallScreen ? '50px' : ''
        },
        hr: {
            border: 'none',
            margin: 0,
            borderTop: '1px solid gray',
        },
        heading: {
            fontWeight: 500,
        },
        socialIcons: {
            display: 'flex',
            gap: '10px',
            zIndex: 9,
            justifyContent: isMediumScreen || isSmallScreen ? 'center' : 'left',

        },
        socialIcon: {
            width: '40px',
            height: '40px',
            borderRadius: '50%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'rgb(255, 233, 209)',
        },
    };

    return (
        <div style={{ background: 'white', position: 'relative' }}>
            <div style={styles.container}>
                {/* {
                    darkMode && (
                        <div className='blurDiv2' style={{ zIndex: 2, left: 0 }}></div>
                    )
                } */}
                <div style={styles.column}>



                    <div style={styles.logo} onClick={() => handleClick('/')}>
                        <img
                            src={darkMode ? '/images/white-logo - Copy.png' : '/images/main-logo - Copy.png'}
                            alt="Logo"
                            style={{ width: '70%', marginBottom: '5%' }}
                        />
                    </div>


                    <div style={styles.socialIcons}>
                        <a href='https://www.facebook.com/share/1Eefgv7Af6/' target='_blank' >  <img src='/images/facebook.svg' style={{ width: 20, height: 20, cursor: 'pointer' }} /></a>
                        {/* <a href='' target='_blank' > <img src='/images/twitter.svg' style={{ width: 20, height: 20, cursor: 'pointer' }} /></a> */}
                        <a href='https://www.instagram.com/milkpromise?igsh=MWtmNmI4emltZ2swMQ==' target='_blank' ><img src='/images/instagram.svg' style={{ width: 20, height: 20, cursor: 'pointer' }} /></a>
                        <a href='https://www.linkedin.com/company/milk-promise/' target='_blank' >    <img src='/images/linkedin.svg' style={{ width: 20, height: 20, cursor: 'pointer' }} /></a>
                    </div>
                </div>
                <div style={styles.column}>
                    <h3 style={styles.heading}>Company</h3>
                    <p style={styles.link} onClick={() => { navigate('/'); window.scrollTo(0, 0) }}>Home</p>
                    <p style={styles.link} onClick={() => { navigate('/about'); window.scrollTo(0, 0) }}>About us</p>
                    <p style={styles.link} onClick={() => { navigate('/products'); window.scrollTo(0, 0) }}>Milk Product</p>
                    <p style={styles.link} onClick={() => { navigate('/contact'); window.scrollTo(0, 0) }}>Contact us</p>
                </div>
                <div style={styles.column}>
                    <h3 style={styles.heading}>Legal</h3>
                    <p style={styles.link} onClick={() => { navigate('/refund-policy'); window.scrollTo(0, 0) }}>Refund Policy</p>
                    <p style={styles.link} onClick={() => { navigate('/terms'); window.scrollTo(0, 0) }}>Terms and Conditions</p>
                </div>
                <div style={styles.column}>
                    <h3 style={styles.heading}>Reach us</h3>
                    <p style={styles.description}>
                        #119, Billapura, Attibele-Sarjapura Road, Near Water Tank, Bangalore, Karnataka, India - 562125
                    </p>
                    <p style={{ ...styles.description, display: 'flex', alignItems: 'center', gap: 5, marginBottom: 0, justifyContent: isSmallScreen || isMediumScreen ? 'center' : '' }}>
                        <PhoneIcon style={{ width: 25, height: 25 }} />
                        <a style={{ textDecoration: 'none', color: darkMode ? 'white' : 'black' }} href="tel:+919844884464">+91 9844884464</a>
                    </p>
                    <p style={{ ...styles.description, display: 'flex', alignItems: 'center', gap: 5, justifyContent: isSmallScreen || isMediumScreen ? 'center' : '' }}>
                        <MdOutlineMail style={{ width: 25, height: 25 }} />
                        <a style={{ textDecoration: 'none', color: darkMode ? 'white' : 'black' }} href="mailto:contact.milkpromise@gmail.com">contact.milkpromise@gmail.com</a>
                    </p>
                </div>
            </div>
            {
                !darkMode && (
                    <hr style={{ opacity: '70%' }} />
                )
            }
            <div style={styles.bottomBar}>
                {/* Copyright © 2024 Company All rights Reserved */}
                <img src="/images/bottom-img.png" alt="Bottom Milk Img" width={isSmallScreen ? '100%' : '37%'} style={{ position: 'absolute', bottom: 0, left: 0 }} />

                <div style={{ marginLeft: isSmallScreen ? '' : '43%', marginTop: '20%' }}>
                    <span style={{ color: '#fff', textAlign: 'right' }}>Developed by</span> <a style={{ textDecoration: 'none', color: primaryColor, cursor: 'pointer' }} target='_blank' href='https://codesteptech.com/'>CodeStep Technologies</a>
                </div>
            </div>
        </div>
    );
};

export default Footer;