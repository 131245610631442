import { useMediaQuery } from '@mui/material';
import React, { useContext } from 'react';
import styled, { css } from 'styled-components';
import { primaryColor, primaryDarkColor } from '../../constant';
import { useTheme } from '@mui/material/styles';
import { DarkModeContext } from '../DarkModeContext';

const FarmtoDoorSection = () => {

    const theme = useTheme();
    const matches_md = useMediaQuery(theme.breakpoints.down('md'));
    const { darkMode } = useContext(DarkModeContext);

    const containerStyles = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        color: darkMode ? 'white' : '',
        justifyContent: 'center',
        backgroundColor: '#fff',
        padding: '1px 20px',
        '@media (max-width: 768px)': {
            padding: '20px',
        },
        '@media (max-width: 480px)': {
            padding: '10px',
        },
    };

    const sectionStyles = {
        display: 'flex',
        alignItems: 'start',
        justifyContent: 'space-between',
        margin: '20px 0',
        width: '100%',
        maxWidth: '1200px',
        '@media (max-width: 768px)': {
            flexDirection: 'column',
            alignItems: 'center',
        },
    };

    const textContainerStyles = {
        flex: 1,
        padding: '0 20px',
        '@media (max-width: 768px)': {
            padding: '10px 0',
        },
    };

    const imageContainerStyles = {
        flex: 1,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '20px',
        flexWrap: 'wrap',
        position: 'relative',
        '> div': {
            position: 'relative',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        img: {
            width: '100%',
            maxWidth: '400px',
            height: 'auto',
            borderRadius: '10px',
            transition: '0.3s',
            '@media (max-width: 768px)': {
                maxWidth: '300px',
            },
        },


    };

    const applyStyles = (styles) => css(styles);

    const Container = styled.div`${applyStyles(containerStyles)}`;
    const Section = styled.div`${applyStyles(sectionStyles)}`;
    const TextContainer = styled.div`${applyStyles(textContainerStyles)}`;
    const ImageContainer = styled.div`${applyStyles(imageContainerStyles)}`;

    const listItemStyles = {
        marginBottom: '15px',
    };

    return (
        <div style={{ background: '#fff', padding: matches_md ? '0 0 7%' : '0 2%', position: 'relative' }}>
            {/* {
                darkMode && (
                    <div className='blurDiv2' style={{ zIndex: 1 }}></div>
                )
            } */}
            <Container>
                <div style={{ position: 'relative' }}>
                    <h1 style={{ color: primaryColor, fontWeight: 600, fontSize: matches_md ? 18 : 30, textAlign: 'center' }}>
                        Quality and Freshness from the Farm to Your Door
                    </h1>
                </div>
                <Section style={{ position: 'relative', marginTop: matches_md ? '30px' : '50px', }}>
                    <ImageContainer style={{ order: matches_md ? 1 : 2 }}>
                        <img src="/images/Q&F.png" alt="Image 2" />
                    </ImageContainer>
                    <div className='blurDiv2' style={{ position: 'absolute', right: 0, top: 0, zIndex: -1 }}></div>
                    <TextContainer style={{ order: matches_md ? 2 : 1 }}>
                        <h3 style={{ fontWeight: 600, fontSize: matches_md ? 16 : 20, color: primaryColor, textAlign: 'left', marginTop: matches_md ? '5%' : '8%' }}>
                            Our process ensures that you receive the freshest milk possible
                        </h3>
                        <div style={{ fontSize: matches_md ? 14 : 18, lineHeight: matches_md ? '4vw' : '1.5vw', textAlign: matches_md ? 'left' : '', marginTop: matches_md ? '10%' : '5%', color:'#000'}}>
                            <ol>
                                <li style={listItemStyles}>Collection: Milk is collected directly from local farms in the early morning and evening.</li>
                                <li style={listItemStyles}>Testing: Each batch is rigorously tested for quality and purity, including micro-testing and fat content analysis.</li>
                                <li>Packaging: Milk is immediately packed in food-grade aluminum foil pouches to maintain freshness and hygiene.</li>
                            </ol>
                        </div>
                    </TextContainer>
                </Section>
            </Container>
        </div>
    );
}

export default FarmtoDoorSection;
