import React, { useContext, useEffect, useState } from 'react';
import { useMediaQuery, Box, Typography } from '@mui/material';
import Header from '../Components/Header';
import Footer from '../Components/Footer';
import Newsletter from '../Components/Newsletter';
import { primaryColor, primaryDarkColor } from '../../constant';
import { DarkModeContext } from '../DarkModeContext';

const Terms = () => {

    const isDesktop = useMediaQuery('(min-width: 1024px)');
    const isTablet = useMediaQuery('(min-width: 768px) and (max-width: 1023px)');
    const { darkMode } = useContext(DarkModeContext);

    const styles = {
        container: {
            display: 'flex',
            flexDirection: isDesktop ? 'row' : 'column',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: isDesktop ? '3%' : '20px 5px',
            backgroundColor: '#efffef',
        },
        leftSide: {
            flex: 1,
            padding: '20px',
            order: isDesktop ? 1 : 2
        },
        rightSide: {
            flex: 1,
            padding: '20px',
            display: 'flex',
            justifyContent: 'center',
            order: isDesktop ? 2 : 1
        },
        heading: {
            fontSize: isDesktop ? 45 : 32,
            fontWeight: 500,
            marginBottom: '20px',
            marginTop: 0,
        },
        highlight: {
            color: primaryColor,
        },
        description: {
            width: '90%',
            fontSize: '16px',
            marginBottom: '20px',
        },
        cardsContainer: {
            display: 'grid',
            padding: isDesktop ? '1% 4%' : '20px 5px',
            gridTemplateColumns: isDesktop ? 'repeat(3, 1fr)' : isTablet ? 'repeat(2, 1fr)' : '1fr',
            gap: '20px',
        },
        cardSection: {
            padding: isDesktop ? '3% 7%' : '40px 20px',
            position: 'relative'
        },
        image: {
            maxWidth: isDesktop ? '80%' : '100%',
            height: 'auto',
            borderRadius: '10px',
        },

        starIcon: {
            color: '#6F42C1',
            marginRight: '5px',
        },
        ratingText: {
            fontWeight: 'bold',
        },
        bookingText: {
            color: '#757575',
            marginLeft: '5px',
        },
        dottedLine: {
            borderBottom: '1px dotted #757575',
            flex: 1,
            marginLeft: '10px',
        }
    };


    return (
        <>
            <Header />

            <div style={{ ...styles.container, background: darkMode ? primaryDarkColor : '' }} className={darkMode ? '' : 'hero-section'} >
                {
                    darkMode && (
                        <div className='blurDiv2' style={{ zIndex: 2 }}></div>
                    )
                }
                <div style={styles.leftSide}>
                    <h2 style={{ ...styles.heading, color: darkMode ? 'white' : 'black', zIndex: 99 }}>Terms and Conditions</h2>
                </div>
                <div style={styles.rightSide}>
                </div>
            </div>

            <div style={{ padding: isDesktop ? '3% 18%' : '5%', fontSize: 17, background: darkMode ? primaryDarkColor : 'white', color: darkMode ? 'white' : 'black' }}>
                <div style={{ color: '#333', lineHeight: 1.5, padding: '20px', color: darkMode ? 'white' : 'black' }}>
                    <h3 style={{ fontWeight: 600 }}>Refund Eligibility:</h3>
                    <ul style={{ listStyle: 'disc', paddingLeft: 20 }}>
                        <li>
                            Refunds are considered only if there is an issue with the quality or delivery of the milk.
                            Claims must be made within 24 hours of delivery.
                        </li>
                        <li>
                            If you receive a damaged or incorrect product, please contact us immediately with relevant
                            details (including photos, if possible).
                        </li>
                    </ul>

                    <h3 style={{ fontWeight: 600 }}>Refund Process:</h3>
                    <ul style={{ listStyle: 'disc', paddingLeft: 20 }}>
                        <li>
                            To request a refund, please contact our customer support team via email at
                            <a href="contact.milkpromise@gmail.com"> contact.milkpromise@gmail.com</a> or through our contact form on the website.
                        </li>
                        <li>
                            Provide your order number, details of the issue, and any supporting evidence (e.g., photos of
                            the damaged product).
                        </li>
                    </ul>

                    <h3 style={{ fontWeight: 600 }}>Refund Approval:</h3>
                    <ul style={{ listStyle: 'disc', paddingLeft: 20 }}>
                        <li>
                            Once your claim is received, we will review the details and determine the eligibility for a
                            refund.
                        </li>
                        <li>
                            If approved, a refund will be processed to the original payment method within 5-7 business
                            days.
                        </li>
                    </ul>

                    <h3 style={{ fontWeight: 600 }}>Non-Refundable Items:</h3>
                    <ul style={{ listStyle: 'disc', paddingLeft: 20 }}>
                        <li>
                            Refunds will not be provided for issues related to subscription changes, delivery timing
                            adjustments, or personal preference reasons.
                        </li>
                    </ul>

                    <h3 style={{ fontWeight: 600 }}>Exchange Policy:</h3>
                    <ul style={{ listStyle: 'disc', paddingLeft: 20 }}>
                        <li>
                            We do not offer exchanges. If you receive an incorrect item, a refund will be processed, and
                            you may place a new order for the correct product.
                        </li>
                    </ul>

                    <h3 style={{ fontWeight: 600 }}>Contact Information:</h3>
                    <ul style={{ listStyle: 'disc', paddingLeft: 20 }}>
                        <li>
                            For any refund-related queries, please reach out to us at
                            <a href="contact.milkpromise@gmail.com"> contact.milkpromise@gmail.com</a>.
                        </li>
                    </ul>

                    <h3 style={{ fontWeight: 600, marginTop: 40 }}>Terms and Conditions:</h3>

                    <h3 style={{ fontWeight: 600 }}>1. Acceptance of Terms:</h3>
                    <ul style={{ listStyle: 'disc', paddingLeft: 20 }}>
                        <li>
                            By using MilkPromise’s services, you agree to abide by these Terms and Conditions. Please
                            read them carefully before making a purchase or subscribing to our services.
                        </li>
                    </ul>

                    <h3 style={{ fontWeight: 600 }}>2. Service Description:</h3>
                    <ul style={{ listStyle: 'disc', paddingLeft: 20 }}>
                        <li>
                            MilkPromise provides fresh, unprocessed milk delivered directly to your doorstep. Our
                            services include various subscription plans and flexible delivery options.
                        </li>
                    </ul>

                    <h3 style={{ fontWeight: 600 }}>3. Subscription and Orders:</h3>
                    <ul style={{ listStyle: 'disc', paddingLeft: 20 }}>
                        <li>
                            Subscriptions are billed according to the chosen plan (weekly, monthly, or bi-monthly) and
                            are automatically renewed unless canceled by the customer.
                        </li>
                        <li>
                            Orders can be modified or canceled through your account on our website. Please make
                            changes at least 24 hours before the scheduled delivery.
                        </li>
                    </ul>

                    <h3 style={{ fontWeight: 600 }}>4. Payment Terms:</h3>
                    <ul style={{ listStyle: 'disc', paddingLeft: 20 }}>
                        <li>
                            Payments are processed through secure payment gateways. Customers are responsible for
                            providing accurate payment information.
                        </li>
                        <li>
                            All prices are subject to change, and any applicable taxes or delivery charges will be added to
                            your total.
                        </li>
                    </ul>

                    <h3 style={{ fontWeight: 600 }}>5. Delivery and Service:</h3>
                    <ul style={{ listStyle: 'disc', paddingLeft: 20 }}>
                        <li>
                            We strive to deliver products within the specified time slots (morning before 7 AM and
                            evening before 5 PM). However, delivery times may vary based on factors such as traffic and
                            weather conditions.
                        </li>
                        <li>
                            MilkPromise is not liable for delays caused by factors beyond our control.
                        </li>
                    </ul>

                    <h3 style={{ fontWeight: 600 }}>6. Quality Assurance:</h3>
                    <ul style={{ listStyle: 'disc', paddingLeft: 20 }}>
                        <li>
                            We are committed to providing high-quality milk. Each batch is tested for purity and safety.
                            In case of quality issues, please refer to our Refund Policy.
                        </li>
                    </ul>

                    <h3 style={{ fontWeight: 600 }}>7. User Responsibilities:</h3>
                    <ul style={{ listStyle: 'disc', paddingLeft: 20 }}>
                        <li>
                            Customers are responsible for providing accurate delivery information and ensuring that
                            someone is available to receive the delivery.
                        </li>
                        <li>
                            Any changes to the delivery address or subscription details must be updated through your
                            account on our website.
                        </li>
                    </ul>

                    <h3 style={{ fontWeight: 600 }}>8. Intellectual Property:</h3>
                    <ul style={{ listStyle: 'disc', paddingLeft: 20 }}>
                        <li>
                            All content, logos, and trademarks on the MilkPromise website are the property of
                            MilkPromise. Unauthorized use of these assets is prohibited.
                        </li>
                    </ul>

                    <h3 style={{ fontWeight: 600 }}>9. Privacy Policy:</h3>
                    <ul style={{ listStyle: 'disc', paddingLeft: 20 }}>
                        <li>
                            Your personal information is collected and used in accordance with our Privacy Policy, which
                            is available on our website.
                        </li>
                    </ul>

                    <h3 style={{ fontWeight: 600 }}>10. Limitation of Liability:</h3>
                    <ul style={{ listStyle: 'disc', paddingLeft: 20 }}>
                        <li>
                            MilkPromise is not liable for any indirect, incidental, or consequential damages arising from
                            the use of our services.
                        </li>
                    </ul>

                    <h3 style={{ fontWeight: 600 }}>11. Changes to Terms:</h3>
                    <ul style={{ listStyle: 'disc', paddingLeft: 20 }}>
                        <li>
                            MilkPromise reserves the right to modify these Terms and Conditions at any time. Any
                            changes will be posted on our website, and your continued use of our services constitutes
                            acceptance of the revised terms.
                        </li>
                    </ul>

                    <h3 style={{ fontWeight: 600 }}>12. Governing Law:</h3>
                    <ul style={{ listStyle: 'disc', paddingLeft: 20 }}>
                        <li>
                            These Terms and Conditions are governed by the laws of India. Any disputes arising from
                            these terms will be resolved in the courts of Bangalore, Karnataka.
                        </li>
                    </ul>

                    <h3 style={{ fontWeight: 600, marginTop: 40 }}>Contact Information:</h3>
                    <ul style={{ listStyle: 'disc', paddingLeft: 20 }}>
                        <li>
                            For further information or inquiries regarding our Refund Policy and Terms and Conditions,
                            please contact us at <a href="mailto:contact.milkpromise@gmail.com"> contact.milkpromise@gmail.com</a>.
                        </li>
                    </ul>

                    <p style={{ marginTop: 20 }}>Thank you for choosing MilkPromise!</p>
                </div>
            </div>

            <Newsletter />
            <Footer />
        </>
    );
};

export default Terms;