import AboutSection from "../Components/AboutSection";
import Footer from "../Components/Footer";
import Header from "../Components/Header";
import Newsletter from "../Components/Newsletter";
import FAQSection from "../Components/Faq";
import WhyChooseSection from "../Components/WhyChoose";
import SaveEnvironmentSection from "../Components/SaveEnvironment";
import FarmtoDoorSection from "../Components/FarmtoDoor";
import { Helmet } from 'react-helmet';

export default function About() {
    return (
        <>
<Helmet>
<title>About MilkPromise | Best Fresh Milk Delivery in Bangalore</title>
<meta
name="description"
content="Learn about MilkPromise, your trusted provider of farm-fresh A2 milk delivery in
Bangalore, including Billapura, Sarjapura, Sompura Gate and Handenahalli."
/>
<meta
name="keywords"
content="Fresh Milk Delivery Bangalore, Organic Milk Home Delivery Bangalore, Dairy Farm
Billapura, Fresh A2 Milk Sarjapura, Cow Milk Delivery Sompura Gate"
/>
</Helmet>
            <Header />
            <AboutSection />
            <SaveEnvironmentSection />
            <WhyChooseSection />
            <FarmtoDoorSection />
            <FAQSection />
            <Newsletter />
            <Footer />
        </>
    )
}