import { useMediaQuery } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { primaryColor, primaryDarkColor } from "../../constant"
import { DarkModeContext } from '../DarkModeContext';

const HeroSection = () => {

    const isDesktop = useMediaQuery('(min-width: 768px)');
    const { darkMode } = useContext(DarkModeContext);

    const headings = ["Welcome to MilkPromise"];

    const [currentHeading, setCurrentHeading] = useState(0);
    const [displayText, setDisplayText] = useState("");
    const [charIndex, setCharIndex] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setDisplayText((prev) => prev + headings[currentHeading][charIndex]);
            setCharIndex((prev) => prev + 1);
        }, 100);

        if (charIndex === headings[currentHeading].length) {
            clearInterval(interval);
            setTimeout(() => {
                setCurrentHeading((prev) => (prev + 1) % headings.length);
                setDisplayText("");
                setCharIndex(0);
            }, 2000);
        }

        return () => clearInterval(interval);
    }, [charIndex, currentHeading, headings]);

    const styles = {
        container: {
            display: 'flex',
            backgroundColor: darkMode ? primaryDarkColor : '',
            flexDirection: isDesktop ? 'row' : 'column',
            justifyContent: 'space-between',
            alignItems: 'center',
            position: 'realtive',
            padding: isDesktop ? '0 5%' : '20px 1px',
        },
        leftSide: {
            flex: 1,
            padding: '0 20px 20px 20px',
            order: isDesktop ? 1 : 2
        },
        rightSide: {
            flex: 1.4,
            padding: '20px',
            display: 'flex',
            justifyContent: 'center',
            order: isDesktop ? 2 : 1
        },
        title: {
            fontSize: 20,
            fontWeight: 500,
        },
        heading: {
            fontSize: isDesktop ? 35 : '32px',
            fontWeight: '700',
            marginBottom: '20px',
            color: 'white',
            marginTop: 0
        },
        highlight: {
            color: 'white',
        },
        description: {
            fontSize: '16px',
            marginTop: isDesktop ? '50px' : '',
            width: isDesktop ? '60%' : '',
            color: darkMode ? 'gray' : ''
        },
        button: {
            padding: isDesktop ? '3% 8%' : '10px 20px',
            fontSize: '16px',
            color: '#fff',
            backgroundColor: primaryColor,
            border: 'none',
            borderRadius: '5px',
            cursor: 'pointer',
        },
        image: {
            maxWidth: isDesktop ? '80%' : '100%',
            height: 'auto',
            zIndex: 99,
            animation: 'float 4s ease-in-out infinite',
            // opacity: darkMode ? '50%' : ''
        },
        watermarkImg: {
            width: isDesktop ? '15%' : '100%',
            marginTop: '5%',
        }
    };

    const highlightStyle = {
        backgroundColor: primaryColor,
        color: '#fff',
        padding: '5px 10px',
        margin: 0,
        width: 'fit-content',
        animation: 'text-float 4s ease-in-out infinite',
        borderRadius: '5px',
    };

    return (
        <>
            <div style={{ paddingTop: 30, background: darkMode ? primaryDarkColor : '' }}
                className={darkMode ? '' : 'hero-section'}
            >
                <h2 style={{ ...styles.heading, textAlign: 'center', fontSize: isDesktop ? 85 : 50, height: 150 }}>{displayText}</h2>

                <div style={styles.container}
                    className={darkMode ? '' : 'hero-section'}
                >
                    {/* {
                        darkMode && (
                            <div className='blurDiv2' style={{ zIndex: 2 }}></div>
                        )
                    } */}
                    <div style={styles.leftSide}>
                        <h2 style={styles.heading}>
                            Pure. Fresh. Natural.
                        </h2>
                        <p style={styles.description}>
                            MilkPromise brings you the finest milk, collected straight from local farmers and delivered to your
                            doorstep. We are committed to providing natural, pure milk without any processing or additives,
                            ensuring you enjoy the freshest product possible.
                        </p>
                        {
                            isDesktop && (
                                <img
                                    src="/images/watermark.svg"
                                    style={styles.watermarkImg}
                                />
                            )
                        }
                    </div>
                    <div style={styles.rightSide}>
                        <img
                            // src="/images/cow-img.svg"
                            src="/images/cow-2.png"
                            alt="Home Maintenance"
                            style={styles.image}
                        />
                    </div>
                    <style>
                        {`
                                    @keyframes float {
                                         0% {
                                              transform: translateY(0) rotate(0);
                                             }
                                         50% {
                                               transform: translateY(-10px) rotate(0);
                                            }
                                         100% {
                                           transform: translateY(0) rotate(0);
                                             }
                                       }
                 `}
                    </style>

                    <style>
                        {`
                                    @keyframes text-float {
                                         0% {
                                              transform: translateX(0) rotate(0);
                                             }
                                         50% {
                                               transform: translateX(-30px) rotate(0);
                                            }
                                         100% {
                                           transform: translateX(0) rotate(0);
                                             }
                                       }
                 `}
                    </style>

                </div>
            </div>
        </>
    );
}


export default HeroSection;
