import { useMediaQuery } from '@mui/material';
import React, { useState, useContext } from 'react';
import styled, { css } from 'styled-components';
import { primaryColor, primaryDarkColor } from '../../constant';
import { useTheme } from '@mui/material/styles';
import { DarkModeContext } from '../DarkModeContext';

const SaveEnvironmentSection = () => {

    const theme = useTheme();
    const matches_md = useMediaQuery(theme.breakpoints.down('md'));
    const { darkMode } = useContext(DarkModeContext);

    const containerStyles = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        color: darkMode ? 'white' : '',
        justifyContent: 'center',
        backgroundColor: '#fff',
        padding: '1px 20px',
        '@media (max-width: 768px)': {
            padding: '20px',
        },
        '@media (max-width: 480px)': {
            padding: '10px',
        },
    };

    const sectionStyles = {
        display: 'flex',
        alignItems: 'start',
        justifyContent: 'space-between',
        margin: '20px 0',
        width: '100%',
        maxWidth: '1200px',
        '@media (max-width: 768px)': {
            flexDirection: 'column',
            alignItems: 'center',
        },
    };

    const textContainerStyles = {
        flex: 1,
        padding: '0 20px',
        '@media (max-width: 768px)': {
            padding: '10px 0',
        },
    };

    const imageContainerStyles = {
        flex: 1,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '20px',
        flexWrap: 'wrap',
        position: 'relative',
        '> div': {
            position: 'relative',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        img: {
            width: '100%',
            height: 'auto',
            borderRadius: '10px',
            transition: '0.3s',
            '@media (max-width: 768px)': {
                maxWidth: '300px',
            },
        },
        'div:hover p': {
            display: 'block',
        },
        'p': {
            display: 'none',
            position: 'absolute',
            bottom: '0',
            left: '0',
            width: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.7)',
            color: 'white',
            padding: '10px',
            boxSizing: 'border-box',
            borderRadius: '10px',
            zIndex: 2,
            textAlign: 'center',
            fontFamily: 'Alegreya Sans, sans-serif',
        },
    };

    const applyStyles = (styles) => css(styles);

    const Container = styled.div`${applyStyles(containerStyles)}`;
    const Section = styled.div`${applyStyles(sectionStyles)}`;
    const TextContainer = styled.div`${applyStyles(textContainerStyles)}`;
    const ImageContainer = styled.div`${applyStyles(imageContainerStyles)}`;

    return (
        <div style={{ background: '#fff', padding: matches_md ? '0 0 7%' : '5% 2% 0', position: 'relative' }}>

            {/* {
                darkMode && (
                    <div className='blurDiv2' style={{ zIndex: 1 }}></div>
                )
            } */}
            <Container>

                <div style={{ position: 'relative' }}>
                    <h1 style={{ color: primaryColor, fontWeight: 600, fontSize: matches_md ? 20 : 30, textAlign: 'center' }}>
                        Save the Environment by Avoiding Plastic
                    </h1>
                </div>
                <Section style={{ marginTop: 0, position: 'relative' }}>
                    {/* {
                        darkMode && (
                            <div className='blurDiv2' style={{ zIndex: 2 }}></div>
                        )
                    } */}
                    {/* <div className='blurDiv2' style={{ position: 'absolute', left: 0, top: 0, zIndex: -1 }}></div> */}
                    <ImageContainer style={{ zIndex: 99 }}>
                        <img src="/images/Save-environment-img.png" alt="Image 1" style={{ width: 500 }} />
                    </ImageContainer>
                    <TextContainer>
                        <p style={{ fontSize: matches_md ? 14 : 18, lineHeight: matches_md ? '4vw' : '1.5vw', textAlign: matches_md ? 'left' : '', marginTop: matches_md ? '' : '25%', fontFamily: 'Alegreya Sans, sans-serif',color:'#000' }}>MilkPromise is committed to reducing plastic waste by
                            using eco-friendly packaging. Our milk is delivered in food-grade aluminum foil pouches, which are
                            not only safe and hygienic but also recyclable. By choosing MilkPromise, you are contributing to a
                            cleaner, greener environment and helping to reduce plastic pollution.</p>
                    </TextContainer>
                </Section>
            </Container>
        </div>
    )
}

export default SaveEnvironmentSection;