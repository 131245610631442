import React, { useContext } from 'react';
import { Box, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { styled } from '@mui/system';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { primaryColor, primaryDarkColor } from '../../constant';
import { DarkModeContext } from '../DarkModeContext';

const NutritionTable = () => {

    const theme = useTheme();
    const matches_md = useMediaQuery(theme.breakpoints.down('md'));
    const { darkMode } = useContext(DarkModeContext);

    return (
        <Box sx={{ padding: { xs: 2, md: 4 }, textAlign: 'center', position: 'relative', background: darkMode ? primaryDarkColor : '' }}>
            {/* {
                darkMode && !matches_md && (
                    <div className='blurDiv2' style={{ zIndex: 2, top: '5%', left: '15%' }}></div>
                )
            } */}
            <img src='/images/nutrition-img.svg' style={{ position: 'absolute', left: '2%', top: '2%', width: matches_md ? 300 : '' }} />
            <center>
                <h2 className='featuresHeading' style={{ textAlign: 'center', padding: matches_md ? '0 4%' : '', fontSize: 40, margin: '8% 0 2%' }}><span style={{ color: 'white' }}>Nutrition Value</span></h2>
                <img src={darkMode ? '/images/nutrition-dark.png' : '/images/nutrition.png'} style={{ width: matches_md ? '100%' : '80%', marginTop: '6%', zIndex: 99 }} />
            </center>

            {/* <TableContainer component={Paper} style={{ width: '70%', margin: 'auto', boxShadow: 'none', border: '1px solid gainsboro', borderRadius: 0 }}>
                <Table aria-label="nutrition table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell>Vitamins</StyledTableCell>
                            <StyledTableCell align="center" style={{ borderLeft: '1px solid gainsboro' }}>Human</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {nutritionData.map((row) => (
                            <TableRow key={row.vitamin}>
                                <TableCell component="th" scope="row">
                                    {row.vitamin}
                                </TableCell>
                                <TableCell align="center" style={{ borderLeft: '1px solid gainsboro', fontSize: 16 }}>{row.human}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer> */}
        </Box>
    );
};

export default NutritionTable;
